import React, { useState, useEffect , useRef } from 'react';
import {  useNavigate } from "react-router-dom"
import { useTranslation } from 'react-i18next'

//Defaults
import HeadBar from '../partials/HeadBar';
import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import config from '../config/config';

//PDF
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import Select from 'react-select'

import { FaChevronCircleDown } from 'react-icons/fa';
import { FiDownload } from 'react-icons/fi';
import { BsCalendar } from 'react-icons/bs';
import { BsSearch } from 'react-icons/bs';

const options = [
  { value: '1', label: 'Älteste' },
  { value: '-1', label: 'Neueste' },
]
    
    
    function SentToSubcontractor() {
      const componentRef = useRef(null);
      const navigate = useNavigate();
    
      //Table components
      const [orderData, setOrderData] = useState('')
      const [push, setPush] = useState(false)
      const [load, setLoad] = useState(false)
      const [search, setSearch] = useState('')
      const [sort, setSort] = useState(-1)

      const [orderId, setOrderId] = useState('')

      const [skip, setSkip] =useState(0)
      const [success, setSuccess] = useState('')
      const [reason, setReason] = useState('')
      const [showModal, setShowModal] = useState(false);

      //Default
      const [isOpen, setIsOpen] = useState(false);
      const [sidebarOpen, setSidebarOpen] = useState(false);
      const [startDate, setStartDate] = useState(null);
      const [endDate, setEndDate] = useState(null);
      const [selectedOption, setSelectedOption] = useState("");
    
      //Default functions
      const handleStartDateChange = (e) => {
        setStartDate(e.target.value);
      };
    
      const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
      };
    
      const handleOptionChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        setSort(selectedOption.value)
      };
    
      const convertDate = date => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const formattedDate = new Date(date).toLocaleDateString('de-DE', options);
        // console.log(formattedDate);
        return formattedDate;
      };
    
      const handleToggle = (id) => {
        const current_row=document.getElementById(id)
          current_row.classList.toggle('expand')
          setIsOpen(!isOpen)
        
        }
    
      function downloadPDF() {
        html2canvas(componentRef.current).then(canvas => {
          const pdf = new jsPDF('l', 'mm', 'a3');
          pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0);
          const dateTemp = "Akzeptierte Angebote"+"_"+new Date().getDate()+"-" +(new Date().getMonth()+1)+"-" +new Date().getFullYear()+"_" +new Date().getHours()+"h-" +new Date().getMinutes()+"m-" +new Date().getSeconds()+"s-" +new Date().getMilliseconds()+"ms.pdf"
          pdf.save(dateTemp)
        });
      }

  const goToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
  
    const interval = setTimeout(() => {
     setSuccess('')
     navigate("/sentoffers");

    }, 5000);
  
    return () => clearInterval(interval);
  
  };

      useEffect(() => {

        let  endDateTemp, startDateTemp
        if(startDate === null || endDate === null || startDate ==null || endDate == null){
         startDateTemp = 0
         endDateTemp = 0
      
        }
        else{
          startDateTemp = startDate
          endDateTemp = endDate
        }
      
    
        fetch(`${config.baseUrl}/api/sentToSub?search=${search}&sort=${sort}&skip=${skip}&datemin=${startDateTemp}&datemax=${endDateTemp}`)
        .then(response => response.json())
        .then((data) =>
           setOrderData(data))
       
      },[load, push, search, sort, skip, startDate, endDate])

 
    
    
      async function handleCancelSubmission(event) {
        setShowModal(false)
        let newDate =Date.now()
       const modifiedDate = new Date(newDate).getDate()+"/" + (new Date(newDate).getMonth() + 1) + "/" + new Date(newDate).getFullYear();
    
        const response = await fetch(
          `${config.baseUrl}/api/cancelOrder`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              id: orderId,
              text:`Cancelled from Sent Offers on ${modifiedDate} `,
              cancellationDate:modifiedDate,
              reason: reason
            }),
          }
        );
    
        const data = await response.json();
    
        if (data.status === 'ok') {
          goToTop()
          setSuccess("success")
        }
        else{
          goToTop()
          setSuccess("error")
        }
      }
    
    
    
      
      const handleCancel = (id) => {
        setOrderId(id)
      setShowModal(true)
      }
    
      const handleManualPayments = (id) => {
        console.log(id)
        localStorage.setItem('manualPaymentId', id)
        navigate('/manualsubpayments')
      }
      
      const view = (id) => {
        localStorage.setItem('sentOfferId', id)
        navigate('/view-sentoffer')
      }
    
      const revise = (id) => {
        localStorage.setItem('revisionId', id)
        navigate('/revisecost')
      }
    
     
      const edit = (id) => {
        localStorage.setItem('edit_id', id)
        navigate('/edit')
      }
    
      const sortOrder = (event) => {
        setSort(event.target.value)
      }
    
      async function onDelete(id) { 
    
        const response = await fetch(`${config.baseUrl}/api/remove`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
           id
          }),
        })
    
        const data = await response.json()
    
        if (data.status === 'ok') {
        
          setOrderData(orderData.filter((val)=>{
            return orderData._id!== id
          }))
          setPush(!push)
          setLoad(!load)
    
     
        }
      }
     
     
      return (
    
        
        <div className="flex h-screen overflow-hidden">
    
    {/* Sidebar */}
    <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
    
    {/* Content area */}
    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
    
      {/*  Site header */}
      <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
    
      <main  ref={componentRef}>
        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
    
          <HeadBar title={"An Subunternehmer gesendet"} description={"Angebote, die an Subunternehmer gesendet werden"}/>
  
          </div>
          {showModal ?  <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    Cancel Orders
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <p className="my-4 text-slate-500 text-lg leading-relaxed">
                  Cancelling Order will put all the related information to this order into <span className='text-red-500 font-semibold' >Cancelled Offers</span> section. This action could not be undone.
                  <br></br>
                  <br></br>
                  An email regarding the same would be sent to the user.
                  </p>
                </div>
                <div class="mb-6 mx-[30px] ">
                <input 
                type="text"
                id="email" 
                onChange={(event) =>
                  setReason(event.target.value)
                }
                value={reason}
                class="bg-white border border-red-300 text-gray-900 text-sm rounded-lg focus:ring-red-800 focus:border-red-800 block w-full p-2.5 dark:bg-white dark:border-red-600 dark:placeholder-gray-400 dark:text-grey dark:focus:ring-red-800 dark:focus:border-red-500 border-red-500 border-2 text-grey" 
                placeholder="Reason for cancelling Order" 
                required ></input>
    </div> 
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                
                
                
                <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                 {reason.length <2 ? 
                 <button disabled
                    className="bg-red-800 text-[#999] active:bg-grey-800 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    
                  >
                    Confirm 
                  </button>
                  :
                  <button
                    className="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="submit"
                    onClick={handleCancelSubmission}
                  >
                    Confirm 
                  </button>}
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      : null}
    
         
          <div className=" max-w-min-content mb-[100px]  col-span-full xl:col-span-12 bg-white shadow-lg rounded-sm border border-slate-200 ml-[45px]">
      <header className="px-5 py-6 border-b border-slate-100">
      <h2 className='font-bold text-slate-800 text-[26px]  '> An Subunternehmer gesendet  <button onClick={downloadPDF} type="button" class="font-semibold inline-block ml-4 text-white bg-violet-500 hover:bg-violet-800  rounded-sm shadow-lg text-sm px-6 py-1.5  ">
      <FiDownload  className='text-white text-[18px] mr-2 mb-0.75 inline-block'  />       Export
                          </button></h2>
      </header>
      <div className="relative px-4">
      <div className="flex  justify-between items-center border rounded-md py-2 px-5 bg-slate-600 max-w-[1100px]">
        <div>
<BsCalendar className='text-white mr-4 inline-block'/>
        <input
          type="date"
          className="text-[14px] font-semibold text-slate-700 focus:outline-none border-0 p-2 w-40 rounded-md  border-gray-300"
          value={startDate}
          onChange={handleStartDateChange}
        />
        <p className="inline-block text-white px-4 ">to</p>
        <input
          type="date"
          className="text-[14px] font-semibold text-slate-700 focus:outline-none border-0 p-2 w-40 rounded-md  border-gray-300"
          value={endDate}
          onChange={handleEndDateChange}
        />
        </div>
        <div></div>
        <div className='flex items-center'>
        <BsSearch className='text-white mr-3'/>
        <input type="text" className='rounded-sm shadow-lg text-slate-700 text-sm w-[200px] px-[15px] mr-8 py-2 inline-block rounded mr-4 focus:none' placeholder="Suche nach Feldern" value={search} onChange={(event) =>setSearch(event.target.value)}/>

       <Select

    className='sm:w-[150px] font-semibold text-[12px] bg-blue-500 shadow-lg  shadow-lg text-slate-700 sm:text-sm text-[12px] rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-[150px] '
      
      options={options}
      value={selectedOption}
      onChange={handleOptionChange}
      placeholder="Anfragen filtern"
    />
    </div>
      </div>
     
    </div>
      <div className="p-3">
    

        {/* Table */}
        <div className="overflow-x-auto"   >


{/* </select> */}

{/* </div> */}
          <table className="table-auto ">
            
            {/* Table header */}
            <>
            <thead className="text-xs font-semibold uppercase text-slate-600 bg-slate-200">
              <tr>
             
             
                <th className="p-2 whitespace-nowrap">
                  <div className="font-bold text-left">Name</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-bold text-left">Kosten</div>
                </th>
               
                <th className="p-2 whitespace-nowrap">
                  <div className="font-bold text-left">Phone</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-bold text-left">Email</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-bold text-left">City</div>
                </th>
                
                         
                               
                                         
                                            
                <th className="p-2 whitespace-nowrap">
                  <div className="font-bold text-left">Edit</div>
                </th>                                
                <th className="p-2 whitespace-nowrap">
                  <div className="font-bold text-left">View</div>
                </th>                                
                <th className="p-2 whitespace-nowrap">
                  <div className="font-bold text-left">Revise Cost</div>
                </th>                                
                <th className="p-2 whitespace-nowrap">
                  <div className="font-bold text-left">Pay Manually</div>
                </th>                                
                <th className="p-2 whitespace-nowrap">
                  <div className="font-bold text-left">Cancel</div>
                </th>                                
                <th className="p-2 whitespace-nowrap">
                  <div className="font-bold text-left">Delete</div>
                </th>                                
                                        
                <th className="p-2 whitespace-nowrap">
                  <div className="font-bold text-left"></div>
                </th>                                
              </tr>
            </thead>
            </>

            <tbody className="text-sm divide-y divide-slate-100">
              {orderData!== '' &&
                orderData.map(order => {
                  return (
                    <>
                 
                    <React.Fragment>
                    <tr className='tr-main w-[100]' key={order._id}>
                   
                 
                         
                      <td className="p-2 whitespace-nowrap">
                          <div className="font-semibold text-slate-800">{ (order.firstName).charAt(0).toUpperCase() + (order.firstName).slice(1).toLowerCase()+" "+(order.lastName).charAt(0).toUpperCase() + (order.lastName).slice(1).toLowerCase() }</div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                          <div className="font-semibold text-slate-800">{order.cost} €</div>
                      </td>
                    
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">{order.phone}</div>
                      </td> 
                    
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">{order.email}</div>
                      </td> 
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">{order.location}</div>
                      </td> 
                     
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">
                        <button  onClick={() =>edit(order._id)}  type="button" class="text-white bg-blue-500 hover:bg-blue-800  font-medium rounded-lg text-sm px-6 py-1.5  ">
                          Edit
                          </button>
                        </div>
                      </td> 
                     
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">
                        <button onClick={() =>view(order._id)} type="button" class="text-white bg-yellow-500 hover:bg-yellow-800  font-medium rounded-lg text-sm px-6 py-1.5 ">
                          View
                          </button>
                        </div>
                      </td> 

                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">
                        <button 
                        onClick={() =>revise(order._id)}
                        type="button" class="text-white hover:bg-green-600 bg-green-500  font-medium rounded-lg text-sm px-6 py-1.5 ">
                          Revise Cost
                          </button>
                        </div>
                      </td> 

                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">
                        <button 
                        onClick={() => handleManualPayments(order._id)}
                        type="button" class="text-white hover:bg-yellow-600 bg-yellow-500  font-medium rounded-lg text-sm px-6 py-1.5 ">
                         Pay Manually
                          </button>
                        </div>
                      </td> 
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">
                        <button 
                         onClick={() => handleCancel(order._id)}
                        type="button" class="text-white hover:bg-red-800 bg-[#800000]  font-medium rounded-lg text-sm px-6 py-1.5 ">
                        Cancel</button>
                        </div>
                      </td> 

                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">
                        <button 
                        onClick ={(() => onDelete(order._id))}
                        type="button" class="text-white bg-red-500 hover:bg-red-800  font-medium rounded-lg text-sm px-6 py-1.5 ">
                          Delete
                          </button>
                        </div>
                      </td> 
                      
                      <td className="p-2 whitespace-nowrap">
                        <button onClick={()=>{handleToggle(order._id)} } className="text-left">
                        <FaChevronCircleDown className={`text-[25px] ${isOpen ? 'rotate' : 'rotate-alt'}`}/>                           {/* <img className='w-[20px]' src="./images/expandtoggle.jpg" alt="" /> */}
                        </button>
                      </td>
                      
                    </tr>
                    <tr className='w-[100] expand' id={order._id}>
                    
                      <td className="p-2 whitespace-nowrap">
                      <small className="text-[13px] text-left font-semibold">Id</small>

                        <div className="text-left">EM-{order._id.slice(-8)}</div>
                      </td>

                      <td className="p-2 whitespace-nowrap">
                      <small className="text-[13px] text-left font-semibold">Salutation</small>

                        <div className="text-left">{order.salutation}</div>
                      </td>

                      <td className="p-2 whitespace-nowrap">
                      <small className="text-[13px] text-left font-semibold">Postcode</small>

                        <div className="text-left">{order.postcode}</div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                      <small className="text-left text-[13px] font-semibold">Area</small>

                        <div className="text-left">{order.area}</div>
                      </td> 
                      <td className="p-2 whitespace-nowrap">
                      <small className="text-left text-[13px] font-semibold">Place</small>

                        <div className="text-left">{order.place}</div>
                      </td> 
                      <td className="p-2 whitespace-nowrap">
                      <small className="text-left text-[13px] font-semibold">Objects</small>

                        <div className="text-left"></div>
                      </td> 
                      <td className="p-2 whitespace-nowrap">
                      <small className="text-left text-[13px] font-semibold">Floor</small>

                        <div className="text-left">{order.floor}</div>
                      </td> 
                      <td className="p-2 whitespace-nowrap">
                      <small className="text-left text-[13px] font-semibold">Elevator </small>

                        <div className="text-left">{order.hasElevator}</div>
                      </td> 
            
                      <td className="p-2 whitespace-nowrap">
                      <small className="text-left text-[13px] font-semibold">House No, Street</small>

                        <div className="text-left">{order.houseNo}, {order.street}</div>
                      </td> 
                      
                    
                      
                      <td className="p-2 whitespace-nowrap">
                      <small className="text-left font-semibold text-[13px]">Created on</small>

                        <div className="text-left">{convertDate(order.createdon)} </div>
                      </td> 
                      
                    </tr>
                    

                    </React.Fragment>
                    </>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>

       </main>
    
      
    
     </div>
    </div>
    );
    }
    
    export default SentToSubcontractor
