import React, { useState, useEffect , useRef } from 'react';
import {  useNavigate } from "react-router-dom"
import { useTranslation } from 'react-i18next'

//Defaults
import HeadBar from '../partials/HeadBar';
import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import config from '../config/config';

//PDF
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import Select from 'react-select'

import { FaChevronCircleDown } from 'react-icons/fa';
import { FiDownload } from 'react-icons/fi';
import { BsCalendar } from 'react-icons/bs';
import { BsSearch } from 'react-icons/bs';

const options = [
  { value: '1', label: 'Älteste' },
  { value: '-1', label: 'Neueste' },
]
    
    
    function AcceptedOffers() {
      const componentRef = useRef(null);
      const navigate = useNavigate();
    
      //Table components
      const [orderData, setOrderData] = useState('')
      const [push, setPush] = useState(false)
      const [load, setLoad] = useState(false)
      const [search, setSearch] = useState('')
      const [sort, setSort] = useState(-1)

      const [orderId, setOrderId] = useState('')

      const [skip, setSkip] =useState(0)
      const [success, setSuccess] = useState('')
      const [reason, setReason] = useState('')
      const [showModal, setShowModal] = useState(false);

      //Default
      const [isOpen, setIsOpen] = useState(false);
      const [sidebarOpen, setSidebarOpen] = useState(false);
      const [startDate, setStartDate] = useState(null);
      const [endDate, setEndDate] = useState(null);
      const [selectedOption, setSelectedOption] = useState("");
    
      //Default functions
      const handleStartDateChange = (e) => {
        setStartDate(e.target.value);
      };
    
      const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
      };
    
      const handleOptionChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        setSort(selectedOption.value)
      };
    
      const convertDate = date => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const formattedDate = new Date(date).toLocaleDateString('de-DE', options);
        // console.log(formattedDate);
        return formattedDate;
      };
    
      const handleToggle = (id) => {
        const current_row=document.getElementById(id)
          current_row.classList.toggle('expand')
          setIsOpen(!isOpen)
        
        }
    
      function downloadPDF() {
        html2canvas(componentRef.current).then(canvas => {
          const pdf = new jsPDF('l', 'mm', 'a3');
          pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0);
          const dateTemp = "Stornierte Angebote"+"_"+new Date().getDate()+"-" +(new Date().getMonth()+1)+"-" +new Date().getFullYear()+"_" +new Date().getHours()+"h-" +new Date().getMinutes()+"m-" +new Date().getSeconds()+"s-" +new Date().getMilliseconds()+"ms.pdf"
          pdf.save(dateTemp)
        });
      }

  const goToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
  
    const interval = setTimeout(() => {
     setSuccess('')
     navigate("/sentoffers");

    }, 5000);
  
    return () => clearInterval(interval);
  
  };

      useEffect(() => {

        let  endDateTemp, startDateTemp
        if(startDate === null || endDate === null || startDate ==null || endDate == null){
         startDateTemp = 0
         endDateTemp = 0
      
        }
        else{
          startDateTemp = startDate
          endDateTemp = endDate
        }
      
    
        fetch(`${config.baseUrl}/api/cancelledOffers?search=${search}&sort=${sort}&skip=${skip}&datemin=${startDateTemp}&datemax=${endDateTemp}`)
        .then(response => response.json())
        .then((data) =>
           setOrderData(data))
       
      },[load, push, search, sort, skip, startDate, endDate])


    
      const viewActive = (id) => {
        localStorage.setItem('sentOfferId', id)
        navigate('/view-sentoffer')
      }
    
      
  




  const edit = (id) => {
    localStorage.setItem('edit_id', id)
    navigate('/edit')
  }



  async function onDelete(id) { 

    const response = await fetch(`${config.baseUrl}/api/remove`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
       id
      }),
    })

    const data = await response.json()

    if (data.status === 'ok') {
    
      setOrderData(orderData.filter((val)=>{
        return orderData._id!== id
      }))
      setPush(!push)
      setLoad(!load)

 
    }
  

 
 
  } 
    
    
     
     
      return (
    
        
        <div className="flex h-screen overflow-hidden">
    
    {/* Sidebar */}
    <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
    
    {/* Content area */}
    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
    
      {/*  Site header */}
      <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
    
      <main  ref={componentRef}>
        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
    
          <HeadBar title={"Stornierte Angebote"} description={"Von Benutzern angenommene Angebote"}/>
  
          </div>
  
    
         
          <div className=" max-w-min-content mb-[100px]  col-span-full xl:col-span-12 bg-white shadow-lg rounded-sm border border-slate-200 ml-[45px]">
      <header className="px-5 py-6 border-b border-slate-100">
      <h2 className='font-bold text-slate-800 text-[26px]  '> Stornierte Angebote  <button onClick={downloadPDF} type="button" className="font-semibold inline-block ml-4 text-white bg-violet-500 hover:bg-violet-800  rounded-sm shadow-lg text-sm px-6 py-1.5  ">
      <FiDownload  className='text-white text-[18px] mr-2 mb-0.75 inline-block'  />       Export
                          </button></h2>
      </header>
      <div className="relative px-4">
      <div className="flex  justify-between items-center border rounded-md py-2 px-5 bg-slate-600 max-w-[1100px]">
        <div>
<BsCalendar className='text-white mr-4 inline-block'/>
        <input
          type="date"
          className="text-[14px] font-semibold text-slate-700 focus:outline-none border-0 p-2 w-40 rounded-md  border-gray-300"
          value={startDate}
          onChange={handleStartDateChange}
        />
        <p className="inline-block text-white px-4 ">to</p>
        <input
          type="date"
          className="text-[14px] font-semibold text-slate-700 focus:outline-none border-0 p-2 w-40 rounded-md  border-gray-300"
          value={endDate}
          onChange={handleEndDateChange}
        />
        </div>
        <div></div>
        <div className='flex items-center'>
        <BsSearch className='text-white mr-3'/>
        <input type="text" className='rounded-sm shadow-lg text-slate-700 text-sm w-[200px] px-[15px] mr-8 py-2 inline-block rounded mr-4 focus:none' placeholder="Suche nach Feldern" value={search} onChange={(event) =>setSearch(event.target.value)}/>

       <Select

    className='sm:w-[150px] font-semibold text-[12px] bg-blue-500 shadow-lg  shadow-lg text-slate-700 sm:text-sm text-[12px] rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-[150px] '
      
      options={options}
      value={selectedOption}
      onChange={handleOptionChange}
      placeholder="Anfragen filtern"
    />
    </div>
      </div>
     
    </div>
      <div className="p-3">
    

        {/* Table */}
        <div className="overflow-x-auto"   >


{/* </select> */}

{/* </div> */}
          <table className="table-auto ">
            
            {/* Table header */}
            <>
            <thead className="text-xs font-semibold uppercase text-slate-600 bg-slate-200">
              <tr>
             
             
                <th className="p-2 whitespace-nowrap">
                  <div className="font-bold text-left">Name</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-bold text-left">City</div>
                </th>
               
                <th className="p-2 whitespace-nowrap">
                  <div className="font-bold text-left">Phone</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-bold text-left">Email</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-bold text-left">Area</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-bold text-left">Postcode</div>
                </th>
             
                
                         
                               
                                         
                                            
                <th className="p-2 whitespace-nowrap">
                  <div className="font-bold text-left">Edit</div>
                </th>                                
                <th className="p-2 whitespace-nowrap">
                  <div className="font-bold text-left">View</div>
                </th>                                
                         
                <th className="p-2 whitespace-nowrap">
                  <div className="font-bold text-left">Delete</div>
                </th>                                
                                        
                <th className="p-2 whitespace-nowrap">
                  <div className="font-bold text-left"></div>
                </th>                                
              </tr>
            </thead>
            </>

            <tbody className="text-sm divide-y divide-slate-100">
              {orderData!== '' &&
                orderData.map(order => {
                  return (
                    <>
                 
                    <React.Fragment>
                    <tr className='tr-main w-[100]' key={order._id}>
                   
                 
                         
                      <td className="p-2 whitespace-nowrap">
                          <div className="font-semibold text-slate-800">{ (order.firstName).charAt(0).toUpperCase() + (order.firstName).slice(1).toLowerCase()+" "+(order.lastName).charAt(0).toUpperCase() + (order.lastName).slice(1).toLowerCase() }</div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                          <div className="font-semibold text-slate-800">{order.location} </div>
                      </td>
                    
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">{order.phone}</div>
                      </td> 
                    
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">{order.email}</div>
                      </td> 
                   
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">{order.area}</div>
                      </td> 
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">{order.postcode}</div>
                      </td> 
                     
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">
                        <button  onClick={() =>edit(order._id)}  type="button" className="text-white bg-blue-500 hover:bg-blue-800  font-medium rounded-lg text-sm px-6 py-1.5  ">
                          Edit
                          </button>
                        </div>
                      </td> 
                     
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">
                        <button onClick={() =>viewActive(order._id)} type="button" className="text-white bg-yellow-500 hover:bg-yellow-800  font-medium rounded-lg text-sm px-6 py-1.5 ">
                          View
                          </button>
                        </div>
                      </td>                     
                    

                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">
                        <button 
                        onClick ={(() => onDelete(order._id))}
                        type="button" className="text-white bg-red-500 hover:bg-red-800  font-medium rounded-lg text-sm px-6 py-1.5 ">
                          Delete
                          </button>
                        </div>
                      </td> 
                      
                      <td className="p-2 whitespace-nowrap">
                        <button onClick={()=>{handleToggle(order._id)} } className="text-left">
                        <FaChevronCircleDown className={`text-[25px] ${isOpen ? 'rotate' : 'rotate-alt'}`}/>                           {/* <img className='w-[20px]' src="./images/expandtoggle.jpg" alt="" /> */}
                        </button>
                      </td>
                      
                    </tr>
                    <tr className='w-[100] expand' id={order._id}>
                    
                    <td className="p-2 whitespace-nowrap">
                    <small className="text-[13px] text-left font-semibold">Id</small>

                      <div className="text-left">EM-{order._id.slice(-8)}</div>
                    </td>

                    <td className="p-2 whitespace-nowrap">
                    <small className="text-[13px] text-left font-semibold">Salutation</small>

                      <div className="text-left">{order.salutation}</div>
                    </td>

                
                    <td className="p-2 whitespace-nowrap">
                    <small className="text-left text-[13px] font-semibold">Place</small>

                      <div className="text-left">{order.place}</div>
                    </td> 
                    <td className="p-2 whitespace-nowrap">
                    <small className="text-left text-[13px] font-semibold">Objects</small>

                      <div className="text-left">""</div>
                    </td> 
                    <td className="p-2 whitespace-nowrap">
                    <small className="text-left text-[13px] font-semibold">Floor</small>

                      <div className="text-left">{order.floor}</div>
                    </td> 
                    <td className="p-2 whitespace-nowrap">
                    <small className="text-left text-[13px] font-semibold">Elevator </small>

                      <div className="text-left">{order.hasElevator}</div>
                    </td> 
                    <td className="p-2 whitespace-nowrap">
                    <small className="text-left text-[13px] font-semibold">Street</small>

                      <div className="text-left">{order.street}</div>
                    </td> 
                    <td className="p-2 whitespace-nowrap">
                    <small className="text-left text-[13px] font-semibold">House No</small>

                      <div className="text-left">{order.houseNo}</div>
                    </td> 
                    <td className="p-2 whitespace-nowrap">
                    <small className="text-left font-semibold text-[13px]">Created on</small>

                      <div className="text-left">{convertDate(order.createdon)} </div>
                    </td> 
                    
                  </tr>
                    

                    </React.Fragment>
                    </>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>

       </main>
    
      
    
     </div>
    </div>
    );
    }
    
    export default AcceptedOffers
