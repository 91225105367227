import React, {useState, useEffect, useLayoutEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { useSearchParams } from "react-router-dom";

import "../style/App.css"
import config from '../config/config';

const stripePromise = loadStripe("pk_test_51LG1MNHv4mAQ2elsAUAFHi3ruP5aLIBMK3NOUVOXllM7OMtmJEP7cOswhrKy7UzkoWRztk7Vaw7e2uLTbpVAyqix00Pk3qotIr");

localStorage.setItem('shouldRun', "true")
export default function Payment() {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams();
  const subId = searchParams.get('subId')
  const id = searchParams.get('id')
  const tokenId = searchParams.get('tokenId')

  useEffect(() => {
    if(subId == null || id == null || tokenId == null){
      console.log("yes")
      navigate('/Error403')
    }
  }, []);

  const [clientSecret, setClientSecret] = useState("");
  const [sub, setSub] =useState({});
  const [tokenData, setTokenData] = useState({})
  const [isLoading, setIsLoading] = useState(true)


  useEffect(() => {
    fetch(
      `${config.baseUrl}/api/sub/profile/read?id=${localStorage.getItem("em_sub_id")}`
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setSub(data.sub);
        
      })
      .catch((error) => console.log(error));
  }, []);


  useEffect(() => {
    if(localStorage.getItem('shouldRun') == "true"){
      localStorage.setItem('shouldRun', "false")
      const url = `${config.backendUrl}/api/getTokenInfo?id=${id}&subId=${subId}&tokenId=${tokenId}`
    fetch(url)
    .then(response => response.json())
    .then((data) =>{
  
      console.log("data", data)
      if(data.stage!==3){
        console.log("Stage isn't equal to 3")
        // navigate('/Error403')
      }
      else{
        setIsLoading(false)
      fetch(`${config.backendUrl}/create-payment-intent`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ 
          amount: (data.cost - (data.cost*data.userper/100))*data.subper/100,
          id:data._id,
          subTokenId:searchParams.get('tokenId'),
          subName:sub.firstName+" "+sub.lastName,
          email:sub.email,
          extratok:sub.subTokenId,
          postcode:sub.postcode,
          city:sub.location,
          subId:searchParams.get('subId'),
        }),
      })
        .then((res) => res.json())
        .then((dataset) =>{
          console.log("dataset: ", dataset)
          console.log("dataset.clientSecret: ", dataset.clientSecret)
          setClientSecret(dataset.clientSecret)
        } )
        .catch((error)=> {
          console.log(error)
          console.log("there's am err:", error)

          navigate('/Error403')
        })

      setTokenData(data)

     }
     })
 
    .catch((error)=> {
      console.log(error)
      console.log("there's an err", error)

      navigate('/Error403')
    })

  }
  

  },[])

  


  const appearance = {
    theme: 'stripe'
  };
  const options = {
  
    clientSecret,
    appearance,
    locale:'de',
  
  };
  
  return (
    <div className="lg:flex lg:justify-around max-w-[1200px] mx-[auto] py-[150px]">
       {isLoading ? <img className='mx-[auto]' src='./images/spin.gif' alt='Loading'></img>: <>
    <div className="px-[20px] py-8 bg-gray-100 rounded box flex flex-col lg:mt-[0px] mt-[50px] max-w-[500px] justify-start mx-[auto]">
    <h2 className="mb-6 text-2xl font-extrabold  text-gray-800">
        Bestellübersicht
        </h2>
   
      <div className='mb-3 text-sm'>
        <span className='text-slate-700 inline-block w-[160px]'>Auftragsnummer</span>
        <span className='text-slate-800 w-[230px] inline-block'>{sub._id}</span>
      </div>
     <span className='w-[90%] bg-gray-100 h-[1px]'></span>
      <div className='mb-3 text-sm'>
        <span className='text-slate-700 inline-block w-[160px]'>Vollständiger Name  </span>
        <span className='text-slate-800 w-[230px] inline-block'>{sub.firstName+" "+sub.lastName}</span>
      </div>
     <span className='w-[90%] bg-gray-100 h-[1px]'></span>

   
      <div className='mb-3 text-sm'>
        <span className='text-slate-700 inline-block w-[160px]'>Telefon</span>
        <span className='text-slate-800  w-[230px] inline-block'>{sub.phone}</span>
      </div>

      <span className='w-[90%] bg-gray-100 h-[1px]'></span>


      <div className='mb-3 text-sm'>
        <span className='text-slate-700 inline-block w-[160px]'>Email  </span>
        <span className='text-slate-800 w-[230px] inline-block'>{sub.email}</span>
      </div>
     <span className='w-[90%] bg-gray-100 h-[1px]'></span>
     
     
     <div className='mb-3 text-sm'>
        <span className='text-slate-700 inline-block w-[160px]'>Service</span>
        <span className='text-slate-800 w-[230px] inline-block'>Entsorg Meister-Plattform</span>
      </div>
     <span className='w-[90%] bg-gray-100 h-[1px]'></span>

 
  
     <div className='mb-3 text-sm'>
        <span className='text-slate-700 inline-block w-[160px]'>Standort  </span>
        <span className='text-slate-800 w-[230px] inline-block'>{sub.location}</span>
      </div>



     <span className='w-[90%] bg-gray-100 h-[1px]'></span>

     <div className='mb-3 text-sm'>
        <span className='text-slate-700 inline-block w-[160px]'>Postleitzahl  </span>
        <span className='text-slate-800 w-[230px] inline-block'>{sub.postcode}</span>
      </div>

     <span className='w-[90%] bg-gray-100 h-[1px]'></span>

     <div className='mb-3 text-sm'>
        <span className='text-slate-700 inline-block w-[160px]'>Straße  </span>
        <span className='text-slate-800 w-[230px] inline-block'>{sub.street}</span>
      </div>
     <span className='w-[90%] bg-gray-100 h-[1px]'></span>

     <div className='mb-3 text-sm'>
        <span className='text-slate-700 inline-block w-[160px]'>Kosten  </span>
        <span className='text-slate-800 w-[230px] inline-block'>{(tokenData.cost - (tokenData.cost*tokenData.userper/100))*tokenData.subper/100} €</span>
      </div>
     <span className='w-[90%] bg-gray-100 h-[1px]'></span>


    </div>
    <div className="App lg:block flex flex-col lg:mt-[0px] mt-[50px] ">

    <h1 className='text-slate-800 text-left text-lg mb-[30px] font-bold'>Zu zahlender Betrag: <span className='text-green-400'>{(tokenData.cost - (tokenData.cost*tokenData.userper/100))*tokenData.subper/100} €</span></h1>

      {clientSecret ? (
     
        <Elements options={ options } stripe={stripePromise}>
          <CheckoutForm/>
        </Elements>
      ): <div className="w-[400px] flex-column justify-center align-center">
        <h1 className="text-center text-indigo-400 mx-[auto] font-bold text-[30px] mb-[10px]">Kasse laden</h1>
        <img className="block mx-[auto]"  src="./images/spin.gif" alt="Loading checkout" ></img>
      </div>}
    </div> 
    </>}
    </div>
  
  );
}