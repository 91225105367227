import React, { useState, useEffect, useRef } from "react";
import { useNavigate, NavLink } from "react-router-dom";

import ChartOne from "../components/ChartOne";
import ChartTwo from "../components/ChartTwo";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import WelcomeBanner from "../partials/WelcomeBanner";
import config from "../config/config";
import Block from "../partials/Block";

import { IoIosCheckmarkCircleOutline } from "react-icons/io";

const options = [
  { value: "1", label: "Älteste" },
  { value: "-1", label: "Neueste" },
];

function Dashboard() {
  const componentRef = useRef(null);
  const navigate = useNavigate();

  //Table components
  const [orderData, setOrderData] = useState([]);

  const [totalActive, setTotalActive] = useState(0);
  const [totalComplete, setTotalComplete] = useState(0);
  const [totalSubRev, setTotalSubRev] = useState(0);
  const [completionDate, setCompletionDate] = useState(0);

  const [push, setPush] = useState(false);
  const [load, setLoad] = useState(false);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState(-1);
  const [skip, setSkip] = useState(0);

  //Default
  const [isOpen, setIsOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [rev, setRev] = useState([]);
  const [profit, setProfit] = useState([]);
  const [endDate, setEndDate] = useState(null);
  const [selectedOption, setSelectedOption] = useState("");

  const [lastSevenDaysRevenueData, setLastSevenDaysRevenueData] =useState([])
  const [totalRevenueLast7Days, setTotalRevenueLast7Days] =useState(0)
  const [totalRev, setTotalRev] =useState(0)
  const [dateArraySevenDays, setDateArraySevenDays] =useState([])
  const [revenueArraySevenDays, setRevenueArraySevenDays] =useState([])

  const [monthsArray, setMonthsArray] =useState([])

  const [collectiveAmountArray, setCollectiveAmountArray] =useState([])

  const data =""

  function hasDecimal(number) {
    return number % 1 !== 0;
  }

  useEffect(() => {
    fetch(`${config.baseUrl}/api/lastSevenDaysRevenue?id=${localStorage.getItem("em_sub_id")}`)
    .then(response => response.json())
    .then((data) =>{ 
      setLastSevenDaysRevenueData(data)
      const amountsArray = data.map((item) => (item.cost -(item.cost*item.userper/100)) );
      setRevenueArraySevenDays(amountsArray)
      
  
      const dateArray = data.map((item) => {
        const dateStr = item.createdon;
        const dateObj = new Date(dateStr);
      
        const day = dateObj.getUTCDate();
        const month = dateObj.getUTCMonth() + 1; // Months are zero-based
        const year = dateObj.getUTCFullYear();
      
        return `${day}-${month}-${year}`;
      });
      
      setDateArraySevenDays(dateArray)
  
      console.log("first", amountsArray, dateArray)
      const totalCost = data.reduce((total, request) => {
        return total + ((request.cost  - (request.cost *request.userper/100)) );
      }, 0);
  
  const formattedCost = totalCost;
  
  if(hasDecimal(formattedCost)){
    formattedCost = formattedCost.toFixed(1)
  }
  setTotalRevenueLast7Days(formattedCost);  
  
    }
    )
  },[])


  useEffect(() => {
    fetch(
      `${config.baseUrl}/api/sub/revenue?id=${localStorage.getItem(
        "em_sub_id"
      )}&search=${search}&sort=${sort}&skip=${skip}`
    )
      .then((response) => response.json())
      .then((data) => {

        const newRev = data.map((request) => {
          return (request.cost - (request.cost * request.userper/100) )*request.subper/100;
        });
        setRev(newRev);

        

        const totalCost = data.reduce(
          (total, request) =>
            total + (request.cost - (request.cost * request.userper) / 100),
          0
        );
 
        const formattedCost = totalCost.toFixed(0);
        console.log(formattedCost);
        setTotalSubRev(formattedCost);


        const total = data.reduce(
          (total, request) =>
            total + (request.cost - (request.cost * request.userper/ 100)) ,
          0
        );

        setTotalRev(total);

        const amountArray = data.map(item => item.cost);

    const createdOnArray = data.map(item => item.date);

  console.log("amountArray", amountArray);

  const monthlyAmounts = createdOnArray.map(createdOn => {
    const date = new Date(createdOn);
    const month = date.getMonth() + 1; // Increment month value by 1
    const year = date.getFullYear();
    return { month, year };
  });
  

const monthlyIncome = monthlyAmounts.reduce((acc, curr) => {
  const key = `${curr.month}-${curr.year}`;
  if (!acc[key]) {
    acc[key] = 0;
  }
  acc[key] += amountArray[monthlyAmounts.indexOf(curr)];
  return acc;
}, {});

const sortedMonthlyIncome = Object.keys(monthlyIncome)
  .sort((a, b) => {
    const [monthA, yearA] = a.split("-");
    const [monthB, yearB] = b.split("-");
    return new Date(yearA, monthA) - new Date(yearB, monthB);
  })
  .reduce((acc, key) => {
    acc[key] = monthlyIncome[key];
    return acc;
  }, {});

  // Array to store the months
const monthsArray = [];
// Array to store the collective monthly amounts
const collectiveAmountsArray = [];

const monthNames = [
  "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];


// Loop through the sortedMonthlyIncome object and extract the month names and collective amounts
for (const key in sortedMonthlyIncome) {
  if (sortedMonthlyIncome.hasOwnProperty(key)) {
    const [month, year] = key.split("-");
    const collectiveAmount = sortedMonthlyIncome[key];

    // Convert month number to month name
    const monthName = monthNames[parseInt(month) - 1];

    monthsArray.push(monthName);
    collectiveAmountsArray.push(collectiveAmount);
  }
}

setMonthsArray(monthsArray)
setCollectiveAmountArray(collectiveAmountsArray)

console.log("monthsArray", monthsArray);
console.log("collectiveAmountsArray", `${collectiveAmountsArray} €`);


      });
  }, [load, push, search, sort, skip]);

  useEffect(() => {
    fetch(
      `${config.baseUrl}/api/sub/totalactive?id=${localStorage.getItem(
        "em_sub_id"
      )}`
    )
      .then((response) => response.json())
      .then((data) => setTotalActive(data.count));
  }, []);

  useEffect(() => {
    fetch(
      `${config.baseUrl}/api/sub/totalcomplete?id=${localStorage.getItem(
        "em_sub_id"
      )}`
    )
      .then((response) => response.json())
      .then((data) => setTotalComplete(data.count));
  }, []);

  function exportCSV() {}

  //Default functions
  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };


  const handleClick = (subTokenId, orderId, toPay) => {

    localStorage.setItem("subToken__Id",subTokenId);
    localStorage.setItem("order__id",orderId);
    localStorage.setItem("to__pay",toPay);
    navigate("/accept-tnc");
    
  };
  
  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleObj = (arr) => {
    const values = arr.map((obj) => obj.value).join(", ");
    console.log("values", values);
    return values;
  };

  const handleOptionChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setSort(selectedOption.value);
  };

  const convertDate = (date) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    const formattedDate = new Date(date).toLocaleDateString("de-DE", options);
    // console.log(formattedDate);
    return formattedDate;
  };

  const handleToggle = (id) => {
    const current_row = document.getElementById(id);
    current_row.classList.toggle("expand");
    setIsOpen(!isOpen);
  };

  //Fetching new order requests : Array of users
  useEffect(() => {
    fetch(
      `${config.baseUrl}/api/sub/neworders/read?id=${localStorage.getItem(
        "em_sub_id"
      )}`
    )
      .then((response) => response.json())
      .then((data) => {
        setOrderData(data);
      });
  }, [push]);

  //View new request
  const view = (id) => {
    localStorage.setItem("viewNewRequestId", id);
    console.log(id);
    navigate("/viewnewrequest");
  };

  //Reject new request
  async function onDelete(id) {
    const response = await fetch(`${config.baseUrl}/api/remove`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id,
      }),
    });

    const data = await response.json();

    if (data.status === "ok") {
      setOrderData(
        orderData.filter((val) => {
          return orderData._id !== id;
        })
      );
      setPush(!push);
      setLoad(!load);
    }
  }

  return (
    <div className=" bg-[#f5f7fe] flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main ref={componentRef}>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Welcome banner */}
            <WelcomeBanner />

            {/* Dashboard actions */}
    
            <div className="flex ">
              <Block
                title={"Neue Anfragen"}
                index={1}
                count={totalActive}
                profit={true}
                change={"+10%"}
              />
              <Block
                title={"Aktive Bestellungen"}
                index={2}
                count={totalActive}
                profit={true}
                change={"+10%"}
              />
              <Block
                title={"Abgeschlossene Bestellungen"}
                index={3}
                count={totalComplete}
                profit={false}
                change={"+10%"}
              />
              {/* Total Revenue */}
              <Block
                title={"Gesamteinnahmen"}
                index={4}
                count={`${totalRev} €`}
                profit={true}
                change={"+0%"}
              />

              <Block
                title={"Gesamteinnahmen (Letzten 7 Tage)"}
                index={5}
                count={totalRevenueLast7Days +" €"}
                profit={true}
                change={"+0%"}
              />
            </div>
          </div>

          <div className="flex w-full  max-w-[1300px] px-8">

        
          <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default  sm:px-7.5 xl:pb-1">
      <h4 className="mb-6 text-xl font-semibold text-black ">
      Gesamteinnahmen (Letzten 7 Tage)
      </h4>

      <div className="flex flex-col">
        <div className="grid grid-cols-3 rounded-sm bg-gray-2 sm:grid-cols-4">
          <div className="p-2.5 xl:p-5">
            <h5 className="text-sm text-center font-medium uppercase ">
            Kundin
            </h5>
          </div>
          <div className="p-2.5 xl:p-5">
            <h5 className="text-sm text-center font-medium uppercase ">
            Datum
            </h5>
          </div>
          <div className=" p-2.5 text-center xl:p-5">
            <h5 className="text-sm font-medium uppercase ">
            Telefon
            </h5>
          </div>
          <div className="p-2.5 text-center xl:p-5">
            <h5 className="text-sm font-medium uppercase">
            Menge 
            </h5>
          </div>
        
        
        </div>
         {lastSevenDaysRevenueData.length > 0 && lastSevenDaysRevenueData.map((data) => {
  return ( 
    <>             
        <div className="grid grid-cols-3 text-sm  border-b border-stroke  sm:grid-cols-4">
          <div className="flex items-center gap-3 p-2.5 xl:p-5">
            <div className="flex-shrink-0">
            </div>
            <p className=" text-black  sm:block">{data.firstName +" "+ data.lastName} </p>
          </div>

          <div className="flex  items-center justify-center p-2.5 xl:p-5">
            <p className="text-black ">{data.date }</p>
          </div>

          <div className="flex  items-center justify-center p-2.5 xl:p-5">
            <p className="text-black ">{data.phone}</p>
          </div>

          <div className="flex items-center text-[#4dcba1] justify-center p-2.5 xl:p-5">
            <p className="font-sembold text-[#32d362]">{(data.cost - data.cost *data.userper/100) * data.subper/100 } €</p>
          </div>

         

          

        </div>
        </>
         )
      })} 

      </div>
    </div>
          <div className=" max-w-min-content mb-[100px]  col-span-full xl:col-span-12   ml-[45px]">
            <div className="relative px-4">
              <div className="flex  justify-between items-center border rounded-md py-2 px-5 bg-slate-600  min-w-full">
                <h1 className="text-white">Neue Anfragen</h1>
              </div>
            </div>
     {orderData.length> 0 ?       <div className="p-3">
              {/* Table */}
              <div className="overflow-x-auto">
                <table className="table-auto bg-white border shadow-sm w-[400px] min-w-full ">
                  {/* Table header */}
                  <>
                    <thead className="text-xs font-semibold uppercase text-slate-600 bg-slate-200">
                      <tr>
                        <th className="p-2 whitespace-nowrap">
                          <div className="font-bold text-left">Name</div>
                        </th>

                        <th className="p-2 whitespace-nowrap">
                          <div className="font-bold text-left">Wert</div>
                        </th>

                        <th className="p-2 whitespace-nowrap">
                          <div className="font-bold text-left">Akzeptieren</div>
                        </th>

                        <th className="p-2 whitespace-nowrap">
                          <div className="font-bold text-left">Sicht</div>
                        </th>

                        <th className="p-2 whitespace-nowrap">
                          <div className="font-bold text-left"></div>
                        </th>
                      </tr>
                    </thead>
                  </>

                  <tbody className="text-sm divide-y divide-slate-100">
                    {orderData.length > 0 &&
                      orderData.map((order) => {
                        return (
                          <>
                            <React.Fragment>
                              <tr className="tr-main w-[100]" key={order._id}>
                                <td className="p-2 whitespace-nowrap">
                                  <div className="font-normal text-slate-800">
                                    {order.firstName.charAt(0).toUpperCase() +
                                      order.firstName.slice(1).toLowerCase() +
                                      " " +
                                      order.lastName.charAt(0).toUpperCase() +
                                      order.lastName.slice(1).toLowerCase()}
                                  </div>
                                </td>

                                <td className="p-2 whitespace-nowrap">
                                  <div className="font-semibold text-green-500">
                                    {order.cost -
                                      (order.cost * order.userper) / 100}{" "}
                                    €
                                  </div>
                                </td>

                                <td className="p-2 whitespace-nowrap">
                                  <div className="text-left">
                                  <button
                                       onClick={()=>handleClick(order.subTokenId, order._id, (order.cost- (order.cost*order.userper/100))*order.subper/100)}
                                      className="bg-[#676aee] inline-flex items-center gap-2 rounded-md px-4 py-2 text-sm text-white hover:text-gray-200 transition-all hover:bg-indigo-700 focus:relative"
                                    >
                                     <IoIosCheckmarkCircleOutline  className="text-white" />
                                     Akzeptieren
                                    </button>
                                  </div>
                                </td>
                                <td className="p-2 whitespace-nowrap">
                                  <div className="text-left">
                                    <button
                                      className="bg-gray-200 inline-flex items-center gap-2 rounded-md px-4 py-2 text-sm text-gray-500  hover:bg-gray-300 transition-all hover:text-gray-800 focus:relative"
                                      onClick={() => view(order._id)}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        class="h-4 w-4"
                                      >
                                        <path
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                        />
                                        <path
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                        />
                                      </svg>
                                      Sicht
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            </React.Fragment>
                          </>
                        );
                      })
                      
                   
                            
                            }
                  </tbody>
                </table>
              </div>
            </div> :
            <>
         <p className="text-center text-xl font-bold text-slate-800 my-4">Keine neuen Bestellungen</p>
            <img className="h-64" src="https://entsorg-meistet-bucket.fra1.digitaloceanspaces.com/em/empty-box.webp" alt="No orders" />
            </> 
            
            }
          </div>
          </div>


          <div className="mt-4 px-8 mb-6 max-w-[1300px] grid grid-cols-12 gap-4 md:mt-6 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
    {monthsArray.length> 0 && collectiveAmountArray.length > 0 ? 
        <ChartOne monthsArray={monthsArray} amountArray={collectiveAmountArray} />

                  :null  }
       {revenueArraySevenDays.length> 0 ?   
       <ChartTwo  amountArray={revenueArraySevenDays} dateArray={dateArraySevenDays}  />
     : null}
       
   
      </div>
        </main>
      </div>
    </div>
  );
}

export default Dashboard;
