import React, { useState} from 'react';
import {  useNavigate, Link } from "react-router-dom"


//Defaults
import HeadBar from '../partials/HeadBar';
import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import config from '../config/config';

function Success() {

 
  const [isOpen, setIsOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);


  return (
    <div className="flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main  >
        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
    

</div>
<div className="w-[90vw] max-w-[500px] shadow-lg shadow rounded-lg  mx-[auto]">
        <p className=' text-align bg-[#64E003] bg- font-semibold px-[20px] rounded-lg py-[8px] text-white'>Zahlung war erfolgreich</p>
        <p className=' text-align text-[#64E003] text-center mt-[10px] font-semibold'><img className='w-[16px] inline-block mr-1' src="./images/welcometick.png" alt=""></img>Herzlichen Glückwunsch, Sie haben die Bestellung erfolgreich erhalten!</p>
        <img className='block mx-auto px-[20px] py-[20px] max-w-[300px] w-[70%]' src="https://entsorg-meistet-bucket.fra1.digitaloceanspaces.com/em%2Fpayment-success-6476727-5373654.png" alt="" />
    </div>  
    <button className="mb-16 mx-[auto] mt-[40px] block transition-all shadow-lg shadow-[#0e59d3]-500/40 hover:shadow-[#5E94EB]/40 hover:bg-[#0e59d3] bg-[#5E94EB] py-2 font-semibold px-8 rounded text-white"><Link to="/activeorders" >Gehen Sie zu Aktive Bestellungen</Link></button>

        </main>

       

      </div>
    </div>
  );
}

export default Success;