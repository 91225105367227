import React,{useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useNavigate} from 'react-router-dom'


const Sidebar = () => {
  const { t } = useTranslation()
  const [count, setCount] = useState(null)

  const navigate =useNavigate();

  useEffect(() => {
		const email = localStorage.getItem('email')
			if (!email) {
        // console.log("email not present");
				localStorage.removeItem('email')
				navigate('/login')
			} 
      else{
        // console.log("email present");
      }
	}, [])

  useEffect(() => {
      fetch("http://localhost:3002/api/totalnewreq")
      .then(response => response.json())
      .then((data) => {
        setCount(data.count)
  
      })

    },[]);
  
  return (   
  <section className='w-[300px] bg-gradient-to-r from-[#6FA6FF] to-indigo-500 sidebar py-[40px] z-[1]  text-white h-[100vh] fixed top-0 left-0 overflow-y-auto'>
    <img className='h-[40px] px-[30px] mb-[60px]' src="./images/logo-white.png" alt="Logo" />
    <ul className='font-semibold  py-[30px] text-left mx-[20px] my-[20px]  bg-[white] text-[#333] rounded-md shadow-sm shadow-white'>
        {/* <p className=' text-2xl px-[30px] w-[200px] mb-[20px]'>{t('dashboard')}</p> */}
       <NavLink to='/'> <li className='flex items-center px-[30px] py-[15px] hover:bg-[#B5E9FF] transition-all '><img className='inline-block h-[16px] pr-2' src="./images/home.png" alt="" /><span className='transition-all'> {t('home')}</span></li></NavLink>
       <NavLink to='/newrequest'> <li className='flex items-center px-[30px] py-[15px] hover:bg-[#B5E9FF] transition-all '><img className='inline-block h-[18px] pr-2' src="./images/new.png" alt="" /><p className='flex'><span className=' transition-all mr-1'> New Requests</span> 
        <span className=' w-[22px] h-[22px] rounded-full bg-red-500 text-white text-[12px] flex justify-center items-center text-bold'>{count}</span></p></li></NavLink>
       <NavLink to='/addorder'>  <li className='flex items-center px-[30px] py-[15px] hover:bg-[#B5E9FF] transition-all '><img className='inline-block h-[16px] pr-2' src="./images/add.png" alt="" /><span className='transition-all'>Add Order</span></li></NavLink>

       <NavLink to='/sentoffers'> <li className='flex items-center px-[30px] py-[15px] hover:bg-[#B5E9FF] transition-all '><img className='inline-block h-[16px] pr-2' src="./images/sent.png" alt="" /><span className='transition-all'>Sent Offers</span></li></NavLink>
       <NavLink to='/acceptedoffers'> <li className='flex items-center px-[30px] py-[15px] hover:bg-[#B5E9FF] transition-all '><img className='inline-block h-[16px] pr-2' src="./images/accepted.png" alt="" /><span className='transition-all'>Accepted Offers</span></li></NavLink>
       <NavLink to='/cancelledoffers'> <li className='flex items-center px-[30px] py-[15px] hover:bg-[#B5E9FF] transition-all '><img className='inline-block h-[16px] pr-2' src="./images/cancelled.png" alt="" /><span className='transition-all'>Cancelled Offers</span></li></NavLink>
       <NavLink to='/senttosub'> <li className='flex items-center px-[30px] py-[15px] hover:bg-[#B5E9FF] transition-all '><img className='inline-block h-[16px] pr-2' src="./images/sent.png" alt="" /><span className='transition-all'>Sent to Subcontractor</span></li></NavLink>
       <NavLink to='/activeorders'><li className='flex items-center px-[30px] py-[15px] hover:bg-[#B5E9FF] transition-all '><img className='inline-block h-[16px] pr-2' src="./images/active.png" alt="" /><span className='transition-all'>{t('active-order')}</span></li></NavLink>
       <NavLink to='/completedorders'><li className='flex items-center px-[30px] py-[15px] hover:bg-[#B5E9FF] transition-all '><img className='inline-block h-[16px] pr-2' src="./images/completed.png" alt="" /><span className='transition-all'>{t('completed-orders')}</span></li></NavLink>
       <NavLink to='/revenue'><li className='flex items-center px-[30px] py-[15px] hover:bg-[#B5E9FF] transition-all '><img className='inline-block h-[16px] pr-2' src="./images/euro.png" alt="" /><span className='transition-all'> {t('revenue')}</span></li></NavLink>
       <NavLink to='/orderhistory'><li className='flex items-center px-[30px] py-[15px] hover:bg-[#B5E9FF] transition-all '><img className='inline-block h-[16px] pr-2' src="./images/history.png" alt="" /><span className='transition-all'>Order History</span></li></NavLink>

       <NavLink to='/subcontractors'><li className='flex items-center px-[30px] py-[15px] hover:bg-[#B5E9FF] transition-all '><img className='inline-block h-[16px] pr-2' src="./images/subcontratctor.png" alt="" /><span className='transition-all'>{t('subcontractor')} </span></li></NavLink>
       <NavLink to='/query'><li className='flex items-center px-[30px] py-[15px] hover:bg-[#B5E9FF] transition-all '><img className='inline-block h-[16px] pr-2' src="./images/query.png" alt="" /><span className='transition-all'>{t('query')} </span></li></NavLink>

    </ul>
  </section>

  )
}

export default Sidebar