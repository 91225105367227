import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next'


const ViewCert = () => {
    const { t } = useTranslation()
const [name, setName] = useState('')
const [loc, setLoc] = useState('')
const [postcode, setPostcode] = useState('')
const [phone, setPhone] = useState('')
const [email, setEmail] = useState('')
const [address, setAddress] = useState('')
const [vatId, setVatId] = useState('')
const [vatFile, setVatFile] = useState('')


 
 
  useEffect(() => {
    
    fetch(`https://admin.entsorg-meister.de/api/viewsubdata?id=${localStorage.getItem('viewSub_id')}`)
    .then(response => response.json())
    .then((data) =>{
      
        setName(data.name)
        setPostcode(data.postcode)
        setEmail(data.email)
        setPhone(data.phone)
        setLoc(data.location)
        setAddress(data.street)
        setVatId(data.vat)
   
    })
    .catch((error)=> console.log(error))
  },[])

 


  return (
    <section className="main overflow-y-auto ml-[300px] z-0 top-0 w-[calc(100vw-300px)] pt-[30px] pb-[100px] right-0 mt-[70px] min-h-[calc(100vh-70px)] bg-white">

    <h2 className='font-bold text-[#0e0e2b] text-[30px] mb-[30px] ml-[120px]'>View VAT Certificate</h2>
    <div className='ml-[120px] bg-white w-[90%] h-[100%] p-[25px] shadow-lg rounded m-[30px] border-grey border-2 '>
  
   <p className=' p-[30px] font-bold text-[30px] text-blue-400'>No VAT file has been uploaded</p>

   
   </div>
</section>
  )
}

export default ViewCert

