import React, { useState, useEffect } from 'react';
import {  useNavigate } from "react-router-dom"

import { FaChevronCircleDown } from 'react-icons/fa';
import { FiDownload } from 'react-icons/fi';

import moment from 'moment';
import Select from 'react-select'
import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import config from '../config/config';

import { BsCalendar } from 'react-icons/bs';
import { BsSearch } from 'react-icons/bs';
import HeadBar from '../partials/HeadBar';

import Form from '../partials/Form';
import SingleForm from "../components/SingleForm"
import BlogEditor from '../partials/BlogEditor';

function AddBlog() {

    const [sidebarOpen, setSidebarOpen] = useState(false);
  
    





  return (
    <div className="flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            {/* Welcome banner */}
            <HeadBar title={"Blog hinzufügen"} description={"Veröffentlichen Sie von hier aus Blogs"} />
 
           
<BlogEditor/>
          </div>
     
        </main>

      </div>
    </div>
  );
}

export default AddBlog;