import React, { useState, useEffect } from 'react';
import {  useNavigate } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import config from '../config/config';

import { FaChevronCircleDown } from 'react-icons/fa';


function Table() {

  const [orderData, setOrderData] = useState('')
  const [push, setPush] = useState(false)
  const [load, setLoad] = useState(false)
  const [sub, setSub] = useState('')
  const [search, setSearch] = useState('')
  const [sort, setSort] = useState(-1)
  const [skip, setSkip] =useState(0)
  const [isOpen, setIsOpen] = useState(false);

  
  const convertDate = date => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const formattedDate = new Date(date).toLocaleDateString('de-DE', options);
    console.log(formattedDate);
    return formattedDate;
  };

  useEffect(() => {
    fetch(`${config.baseUrl}/api/read?search=${search}&sort=${sort}&skip=${skip}`)
    .then(response => response.json())
    .then((data) => setOrderData(data))
  },[load, push, search, sort, skip])

  const handleToggle = (id) => {
  const current_row=document.getElementById(id)
    current_row.classList.toggle('expand')
    setIsOpen(!isOpen)

  }

  return (
    <div className="col-span-full xl:col-span-12 bg-white shadow-lg rounded-sm border border-slate-200 ml-[45px]">
      <header className="px-5 py-4 border-b border-slate-100">
        <h2 className="font-bold text-[20px] text-slate-800">New Requests</h2>
      </header>
      <div className="p-3">

        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto ">
            {/* Table header */}
            <>
            <thead className="text-xs font-semibold uppercase text-slate-600 bg-slate-200">
              <tr>
              <th className="p-2 whitespace-nowrap">
                  <div className="font-bold text-left ">Order Status</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-bold text-left">Id</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-bold text-left">Name</div>
                </th>

               
                <th className="p-2 whitespace-nowrap">
                  <div className="font-bold text-left">Phone</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-bold text-left">Email</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-bold text-left">City</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-bold text-left">Date</div>
                </th> 
                         
                               
                                         
                                            
                <th className="p-2 whitespace-nowrap">
                  <div className="font-bold text-left">Edit</div>
                </th>                                
                <th className="p-2 whitespace-nowrap">
                  <div className="font-bold text-left">View</div>
                </th>                                
                <th className="p-2 whitespace-nowrap">
                  <div className="font-bold text-left">Delete</div>
                </th>                                
                <th className="p-2 whitespace-nowrap">
                  <div className="font-bold text-left">Send Quote</div>
                </th>                                
                <th className="p-2 whitespace-nowrap">
                  <div className="font-bold text-left"></div>
                </th>                                
              </tr>
            </thead>
            </>

            <tbody className="text-sm divide-y divide-slate-100">
              {orderData!== '' &&
                orderData.map(order => {
                  return (
                    <>
                 
                    <React.Fragment>
                    <tr className='tr-main w-[100]' key={order._id}>
                    {order.orderstatus==='incomplete'? 
                     <td className="p-2 whitespace-nowrap">
                     <div className="text-left">
                     <button type="button" class=" bg-red-100 text-red-500 cursor-default  font-semibold rounded-sm text-sm px-6 py-1.5  ">
                       Incomplete
                       </button>
                     </div>
                   </td> :
                  <td className="p-2 whitespace-nowrap">
                  <div className="text-left">
                  <button type="button" class="text-green-500 bg-green-100 cursor-default   font-semibold rounded-sm text-sm px-6 py-1.5  ">
                    Complete
                    </button>
                  </div>
                </td> 
                }
                   <td className="p-2 whitespace-nowrap">
                          <div className="font-medium text-slate-800">EM-{order._id.slice(-10)}</div>
                      </td>
                         
                      <td className="p-2 whitespace-nowrap">
                          <div className="font-semibold text-slate-800">{order.name}</div>
                      </td>
                    
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">{order.contact}</div>
                      </td> 
                    
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">{order.email}</div>
                      </td> 
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">{order.city}</div>
                      </td> 
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">{convertDate(order.date)}</div>
                      </td> 
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">
                        <button type="button" class="text-white bg-blue-500 hover:bg-blue-800  font-medium rounded-lg text-sm px-6 py-1.5  ">
                          Edit
                          </button>
                        </div>
                      </td> 
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">
                        <button type="button" class="text-white bg-red-500 hover:bg-red-800  font-medium rounded-lg text-sm px-6 py-1.5 ">
                          Delete
                          </button>
                        </div>
                      </td> 
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">
                        <button type="button" class="text-white bg-yellow-500 hover:bg-yellow-800  font-medium rounded-lg text-sm px-6 py-1.5 ">
                          View
                          </button>
                        </div>
                      </td> 
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">
                        <button type="button" class="text-white bg-green-500 hover:bg-green-800  font-medium rounded-lg text-sm px-6 py-1.5 ">
                          Send Quote
                          </button>
                        </div>
                      </td> 
                      
                      <td className="p-2 whitespace-nowrap">
                        <button onClick={()=>{handleToggle(order._id)} } className="text-left">
                        <FaChevronCircleDown className={`text-[25px] ${isOpen ? 'rotate' : 'rotate-alt'}`}/>                           {/* <img className='w-[20px]' src="./images/expandtoggle.jpg" alt="" /> */}
                        </button>
                      </td>
                      
                    </tr>
                    <tr className='w-[100] expand' id={order._id}>
                    
                      <td className="p-2 whitespace-nowrap">
                      <small className="text-[13px] text-left font-semibold">Postcode</small>

                        <div className="text-left">{order.postcode}</div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                      <small className="text-left text-[13px] font-semibold">Area</small>

                        <div className="text-left">{order.sliderVal} m.sq.</div>
                      </td> 
                      <td className="p-2 whitespace-nowrap">
                      <small className="text-left text-[13px] font-semibold">Section</small>

                        <div className="text-left">{order.section}</div>
                      </td> 
                      <td className="p-2 whitespace-nowrap">
                      <small className="text-left text-[13px] font-semibold">Method</small>

                        <div className="text-left">{order.method}</div>
                      </td> 
                      <td className="p-2 whitespace-nowrap">
                      <small className="text-left font-semibold text-[13px]">Created on</small>

                        <div className="text-left">{convertDate(order.createdon)} </div>
                      </td> 
                      
                    </tr>
                    </React.Fragment>
                    </>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Table;
