import React, { useState, useEffect } from 'react';
import {  useNavigate } from "react-router-dom"
import { useTranslation } from 'react-i18next'
const Preview = () => {
  
  const { t } = useTranslation()

  const navigate = useNavigate();
  const [cost, setCost] =useState(null)
  const [userper, setUserPer] =useState(30)
  const [subper, setSubPer] =useState(10)
  const [id, setId] =useState('')

  useEffect(() => {
    setCost(localStorage.getItem('previewCost'))
    setUserPer(localStorage.getItem('previewUserPer'))
    setSubPer(localStorage.getItem('previewSubPer'))
    setId(localStorage.getItem('previewId'))
  },[])


 
  return (
      <>
    

    <section className="main overflow-y-auto ml-[300px] z-0 top-0  pt-[30px] pb-[100px] right-0 mt-[70px] min-h-[calc(100vh-70px)] bg-white" >
    <h2 className='font-bold text-[#0e0e2b] text-[30px] mb-[30px] ml-[120px]'>Preview</h2>

<a href="temp.html">Redirect to Html page</a>

      </section>
      </>
  )
}

export default Preview