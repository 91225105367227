import React, { useState, useEffect , useRef } from 'react';
import {  useNavigate } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { FaChevronCircleDown } from 'react-icons/fa';
import { FiDownload } from 'react-icons/fi';

import moment from 'moment';
import Select from 'react-select'
import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import WelcomeBanner from '../partials/WelcomeBanner';
import config from '../config/config';
import Block from '../partials/Block';

import { BsCalendar } from 'react-icons/bs';
import { BsSearch } from 'react-icons/bs';
import HeadBar from '../partials/HeadBar';

const options = [
  { value: '1', label: 'Älteste' },
  { value: '-1', label: 'Neueste' },

]



function SendQuote() {
    const { t } = useTranslation()

  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [cost, setCost] =useState(null)
  const [userper, setUserper] =useState(30)
  const [subper, setSubper] =useState(10)
  const [id, setId] =useState('')
  const [loading, setLoading] = useState(true)
const [success, setSuccess] = useState('')

const goToTop = () => {
  window.scrollTo({
      top: 0,
      behavior: 'smooth',
  });

  const interval = setTimeout(() => {
   setSuccess('')
   navigate("/newrequest");
  
  }, 5000);

  return () => clearInterval(interval);

};

  useEffect(() => {
    setId(localStorage.getItem('sendQuoteId'))
  },[])


// async function handlePreview(event) { 
//   localStorage.setItem('previewCost', cost)
//   localStorage.setItem('previewUserPer', userper)
//   localStorage.setItem('previewSubPer', subper)
//   localStorage.setItem('previewId', id)
//   navigate('/preview')
// }


async function handleCost(event) { 
  const response = await fetch(`${config.baseUrl}/api/cost`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id:localStorage.getItem('sendQuoteId'),
      cost:cost,
      userper:userper,
      subper:subper,
    }),
  })

  const data = await response.json()

  if (data.status === 'ok') {
    goToTop()
    setSuccess("success")
  }
  else{
    goToTop()
    setSuccess("error")
  }

  

 
} 

  return (
    <div className="flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main  >
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            <HeadBar title={"Neue Anfragen"} description={"Werfen Sie einen Blick auf neue Anfragen"}/>

            <div className=" ml-[20px] w-[90vw] max-w-[800px] rounded text-[#333] bg-white   shadow-lg shadow-grey border-2 border-grey p-[45px]">




<div class="parallel  justify-around items-center mb-[30px]">
  <form onSubmit={(e)=>{e.preventDefault()}}>
  {success==='success' && <div className='mb-[20px]'>
<p className='bg-blue-100 border-[1px] border-solid border-blue-500 w-[100%] px-[30px] py-[10px] '>Quote sent successfully! You will be auto-redirected in a while</p>
</div>}
{success==='error' && <div className='mb-[20px]'>
<p className='bg-red-100 border-[1px] border-solid border-red-500 w-[100%] px-[30px] py-[10px] '>An error occured. Please try again</p>
</div>}
  <div className="form-box mb-[10px] flex">
 <input className='bg-white px-[10px]  py-[5px]  shadow-lg border-2 border-grey' type="number" min={30} required placeholder ="Cost" 
 onChange={event => setCost(event.target.value)} value={cost} />
  <label  className='w-[280px] px-[10px]  py-[5px] text-white bg-blue-700'>{t('cost')} Є</label>
  </div>
  <div className="form-box mb-[10px] flex">
 
  <select className="bg-white px-[10px] w-[220px] py-[5px] shadow-lg border-2 border-grey" required name="userper" defaultValue={userper} onChange={event => setUserper(event.target.value)} >
            <option  value="20">20 %</option>
            <option value="25">25 %</option>
            <option value="30" >30 %</option>
            <option value="35">35 %</option>
        </select>
<label  className='w-[280px] px-[10px]  py-[5px] text-white bg-blue-700'>{t('advance-cost')} % ({t('user')})</label>
  </div>

  <div className="form-box mb-[10px] flex">

  <select className="bg-white px-[10px] w-[220px] py-[5px] shadow-lg border-2 border-grey" required name="subper" defaultValue={subper} onChange={event => setSubper(event.target.value)} >
            <option  value="10" >10 %</option>
            <option value="15">15 %</option>
            <option value="20" >20 %</option>

        </select>    <label  className='w-[280px] px-[10px]  py-[5px] text-white bg-blue-700'>{t('advance-cost')} % ({t('subcontractor')})</label>
   </div>
   </form>
  </div>
  {(cost<1 ) ?  <button disabled onClick ={handleCost} className='px-[30px] mr-[30px] py-[8px] font-semibold text-[18px] text-white bg-green-800 text-grey'>{t('send')}</button>
  :
  <button  onClick ={handleCost} className='px-[30px] mr-[30px] py-[8px] font-semibold text-[18px] text-white bg-green-500 hover:bg-green-700'>{t('send')}</button>}

{/* {(cost<1 ) ?  <button disabled onClick ={handlePreview} className='px-[30px] mr-[30px] py-[6px] font-semibold text-[16px] text-white bg-orange-800 text-grey'>{t('Preview')}</button>
  :
  <button  onClick ={handlePreview} className='px-[30px] mr-[30px] py-[6px] font-semibold text-[16px] text-white bg-orange-500 hover:bg-orange-700'>Preview</button>} */}
</div>

          </div>
   
        </main>

       

      </div>
    </div>
  );
}

export default SendQuote;