import React, { useState, useEffect , useRef } from 'react';
import {  useNavigate } from "react-router-dom"
import { useTranslation } from 'react-i18next'



import moment from 'moment';
import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';

import config from '../config/config';

import HeadBar from '../partials/HeadBar';





function EditSentOffer() {
    const { t } = useTranslation();


    const [loading, setLoading] = useState(true)
    const [success, setSuccess] = useState('')
    const [isOpen, setIsOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [name, setName] = useState('')
const [value, setValue] = useState('')
const [sliderVal, setSliderValue] = useState(0)
const [email, setEmail] = useState('')
const [contact, setContact] = useState('')
const [address, setAddress] = useState('')
const [section, setSection] = useState('')
const [others, setOthers] = useState('')
const [method, setMethod] = useState('')
const [date, setDate] = useState('')
const [floor, setFloor] = useState('')

const [postcode, setPostcode] = useState('')
const [city, setCity] = useState('')
const [description, setDescription] = useState('')
const [orderStatus, setOrderStatus] = useState('')
    const goToTop = () => {
      window.scrollTo({
          top: 0,
          behavior: 'smooth',
      });
    
      const interval = setTimeout(() => {
       setSuccess('')
    
      }, 5000);
    
      return () => clearInterval(interval);
    
    };
  
    const [formData, setFormData] = useState({
      value: "",
      sliderVal: 50,
      name: "",
      email: "",
      contact: "",
      address: "",
      section: "Kitchen",
      others: "",
      method: "Elevator",
      date: null,
      floor: null,
      postcode: "",
      city: "",
      description: "",
      orderstatus: "incomplete",
    });
    const navigate = useNavigate();
    const id = localStorage.getItem("edit_id");
  
    useEffect(() => {
      fetch(
        `${config.baseUrl}.de/api/edit?id=${localStorage.getItem(
          "edit_id"
        )}`
      )
        .then((response) => response.json())
        .then((data) => {
          var DateObj = new Date(data.date).toString();
                        let selectDate = moment(DateObj).format("YYYY-MM-DD");
                     data.date=selectDate
              
                        
          setFormData({
            ...formData,
            value: data.value,
            sliderVal: data.sliderVal,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            phone: data.phone,
            address: data.address,
            section: data.section,
            others: data.others,
            method: data.method,
            date: data.date,
            floor: data.floor,
            postcode: data.postcode,
            city: data.city,
            description: data.description,
          });
          setLoading(false)
       
        })
       
          
        .catch((error) => console.log(error));
    }, []);
  
    async function handleSubmit(event) {
      event.preventDefault();
    }
   
  
    async function handleInfo(event) {
      const response = await fetch(
        "https://admin.entsorg-meister.de/api/updateorder",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: id,
            value: formData.value,
            sliderVal: formData.sliderVal,
            name: formData.name,
            email: formData.email,
            contact: formData.contact,
            address: formData.address,
            section: formData.section,
            others: formData.others,
            method: formData.method,
            floor: formData.floor,
            date: formData.date,
            postcode: formData.postcode,
            city: formData.city,
            description: formData.description,
            orderstatus: "complete",
            stage: 0,
            cost: null,
          }),
        }
      );
  
      const data = await response.json();
  
      if (data.status === 'ok') {
        goToTop()
        setSuccess("success")
      }
      else{
        goToTop()
        setSuccess("error")
      }
    }





 
useEffect(() => {
    console.log(localStorage.getItem('viewNewRequestId'))
    fetch(`${config.baseUrl}/api/viewNewReq?id=${localStorage.getItem('viewNewRequestId')}`)
    .then(response => response.json())
    .then((data) =>{
        console.log(data)
      setEmail(data.email)
        setName(data.name)
        setValue(data.value)
        setSliderValue(data.sliderVal)
        setEmail(data.email)
        setContact(data.contact)
        setSection(data.section)
        setAddress(data.address)
        setOthers(data.others)
        setMethod(data.method)
        setDate(data.date)
        setFloor(data.floor)
        setPostcode(data.postcode)
        setCity(data.city)
        setDescription(data.description)
        setOrderStatus(data.orderstatus)
      
        setLoading(false)

    })
    .catch((error)=> console.log(error))
  },[])

 

 
  return (

    
    <div className="flex h-screen overflow-hidden">

{/* Sidebar */}
<Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

{/* Content area */}
<div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

  {/*  Site header */}
  <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

  <main  >
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

      <HeadBar title={"Gesendete Angebote bearbeiten"} description={"Bearbeiten Sie einzelne gesendete Angebote"}/>


 

      </div>
   
      <form onSubmit={handleSubmit}>
        <div className=" ml-[120px] w-[90vw] max-w-[800px] rounded text-[#333] bg-white flex justify-start  shadow-lg shadow-grey border-2 border-grey p-[45px]">
          <div>
          {loading && <p className=' p-[30px] font-bold text-[30px] text-blue-400'>{t('loading-data')} <img className='inline-block' src="./images/spin.gif" alt="" /></p>
}
   {!loading &&   
            <div className="w-[90vw]  max-w-[700px]">
            {success==='success' && <div className='mb-[20px]'>
  <p className='bg-blue-100 border-[1px] border-solid border-blue-500 w-[100%] px-[30px] py-[10px] '>Angebotsinfo aktualisiert!</p>
</div>}
{success==='error' && <div className='mb-[20px]'>
  <p className='bg-red-100 border-[1px] border-solid border-red-500 w-[100%] px-[30px] py-[10px] '>Es ist ein Fehler aufgetreten</p>
</div>}
              <div>
                <div className=" sm:overflow-hidden sm:rounded-md">
                  <div className="space-y-6 bg-white px-4 py-5 sm:sp-6">

                  <div className="bg-white ">
                      <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                          <label
                            htmlFor="city"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Full Name
                          </label>
                          <input
                            type="name"
                            placeholder="Name"
                            onChange={(event) =>
                              setFormData({ ...formData, name: event.target.value })
                            }
                            value={formData.firstName +" " + formData.lastName}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm w-[90%] border-2 text-[14px] p-[6px] mb-[3px] rounded block mb-10px"
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                          <label
                            htmlFor="region"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Email
                          </label>
                          <input
                           type="email"
                           placeholder="Email"
                           onChange={(event) =>
                             setFormData({ ...formData, email: event.target.value })
                           }
                           value={formData.email}
                            autoComplete="address-level1"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm w-[90%] border-2 text-[14px] p-[6px] mb-[3px] rounded block mb-10px"
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                          <label
                            htmlFor="postal-code"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Phone
                          </label>
                          <input
                             type="text"
                             placeholder={t("phone")}
                             onChange={(event) =>
                               setFormData({ ...formData, contact: event.target.value })
                             }
                             value={formData.phone}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm w-[90%] border-2 text-[14px] p-[6px] mb-[3px] rounded block mb-10px"
                          />
                        </div>
                      </div>
                    </div>

                    {console.log(formData)}
                   
                   
                    <div className="col-span-6 sm:col-span-3">
                          
                    <label className="block text-md font-medium text-gray-800">
                      Select the volume that needs to be cleared out
                    </label>
                          <select
                            required
                            name="value"
                            defaultValue={formData.value}
                            onChange={(event) =>
                              setFormData({ ...formData, value: event.target.value })
                            }
                            className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          >
                             <option value="Low">{t("low")}</option>
                              <option value="Average">{t("average")}</option>
                              <option value="High">{t("high")}</option>
                          </select>
                        </div>

                    <div>
                      <label className="block text-md font-medium text-gray-800">
                        Upload some images of your place
                      </label>
                      <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                        <div className="space-y-1 text-center">
                          <svg
                            className="mx-auto h-12 w-12 text-gray-400"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 48 48"
                            aria-hidden="true"
                          >
                            <path
                              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          <div className="flex text-sm text-gray-600">
                            <label
                              htmlFor="file-upload"
                              className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                            >
                              <span>Upload a file</span>
                              <input
                                id="file-upload"
                                name="file-upload"
                                type="file"
                                className="sr-only"
                              />
                            </label>
                            <p className="pl-1">or drag and drop</p>
                          </div>

                          <p className="text-xs text-gray-500">
                            PNG, JPG up to 3 images
                          </p>
                        </div>
                      </div>
                    </div>

                    <label
                      htmlFor="about"
                      className="block text-sm font-medium text-grey-700"
                    >
                      {t("form-two-area")}
                    </label>
                    <div className="border-2 border-[#888888]  mb-[30px]">
                      <input
                        min="0"
                        max="5000"
                        className="bg-white font-semibold text-[#676767] px-[20px] text-align text-[14px] py-[6px] w-[50%]"
                        type="number"
                        value={formData.sliderVal}
                        onChange={(event) =>
                          setFormData({
                            ...formData,
                            sliderVal: event.target.value,
                          })
                        }
                      />
                      <button
                        disabled
                        className="btn font-semibold text-[#676767] w-[50%] py-[6px] px-[20px] text-[14px] bg-indigo-100"
                      >
                        {t("form-two-area-unit")}
                      </button>
                    </div>

                    <div className="mb-[30px]">
                      <input
                        type="range"
                        name=""
                        onChange={(event) =>
                          setFormData({
                            ...formData,
                            sliderVal: event.target.value,
                          })
                        }
                        value={formData.sliderVal}
                        className="w-[100%]"
                        id=""
                        min="0"
                        step="1"
                        max="5000"
                      />
                    </div>

                  

                    <div className="bg-white ">
                      <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="country"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Place which needs to be decluttered
                          </label>
                          <select
                            required
                            name="section"
                            defaultValue={formData.section}
                            value={formData.section}
                            onChange={(event) =>
                              setFormData({ ...formData, section: event.target.value })
                            }
                            className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          >
                            <option value="Kitchen">{t("kitchen")}</option>
                    <option value="Bedroom">{t("bedroom")}</option>
                    <option value="Loft">{t("loft")}</option>
                    <option value="Basement">{t("basement")}</option>
                    <option value="Others">{t("others")}</option>
                          </select>
                        </div>

                        <div className="col-span-6">
                          <label
                            htmlFor="street-address"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Address
                          </label>
                          <input
                            type="text"
                            onChange={(event) =>
                              setFormData({ ...formData, address: event.target.value })
                            }
                            value={formData.address}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm w-[90%] border-2 text-[14px] p-[6px] mb-[3px] rounded block mb-10px"
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                          <label
                            htmlFor="city"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Floor no.
                          </label>
                          <input
                            type="text"
                            placeholder={t("floor-no")}
                            onChange={(event) =>
                              setFormData({ ...formData, floor: event.target.value })
                            }
                            value={formData.floor}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm w-[90%] border-2 text-[14px] p-[6px] mb-[3px] rounded block mb-10px"
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                          <label
                            htmlFor="region"
                            className="block text-sm font-medium text-gray-700"
                          >
                            City
                          </label>
                          <input
                            type="text"
                            
                            placeholder={t("city")}
                            onChange={(event) =>
                              setFormData({ ...formData, city: event.target.value })
                            }
                            value={formData.city}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm w-[90%] border-2 text-[14px] p-[6px] mb-[3px] rounded block mb-10px"
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                          <label
                            htmlFor="postal-code"
                            className="block text-sm font-medium text-gray-700"
                          >
                            ZIP / Postal code
                          </label>
                          <input
                           type="text"
                           placeholder={t("postcode")}
                           onChange={(event) =>
                             setFormData({ ...formData, postcode: event.target.value })
                           }
                           value={formData.postcode}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm w-[90%] border-2 text-[14px] p-[6px] mb-[3px] rounded block mb-10px"
                          />
                        </div>
                      </div>
                    </div>

                    <label className="block text-sm font-medium text-gray-700">
                      Method of reaching out
                    </label>
                    <select
                            required
                            name="value"
                            defaultValue={formData.value}
                            onChange={(event) =>
                              setFormData({ ...formData, value: event.target.value })
                            }
                            className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          >                   
                          <option value="Elevator">{t("elevator")}</option>
                    <option value="Stairs">{t("stairs")}</option>
                    </select>
                  

                    <div className="col-span-6">
                      <label className="block text-sm font-medium text-gray-700">
                        {t("moving")}
                      </label>
                     
                    
                      <input
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm w-[90%] border-2 text-[14px] p-[6px] mb-[3px] rounded block mb-10px"
                        type="date"
                   
                        defaultValue={formData.date}
                        onChange={(event) =>
                          setFormData({ ...formData, date: event.target.value })
                        }
                        value={formData.date}
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="about"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Description
                      </label>
                      <div className="mt-1 group">
                        <textarea
                          type="text"
                          onChange={(event) =>
                            setFormData({ ...formData, description: event.target.description })
                          }
                          value={formData.description}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm w-[90%] border-2  text-[14px] p-[6px] mb-[3px] rounded  block mb-10px"
                          placeholder="Add additional information about the place"
                          defaultValue={""}
                        />
                      </div>
                    </div>


                    <div className="bg-gray-50 py-3 text-right sm:px-1">

                    {formData.value === "" ||
            formData.sliderVal === null ||
            formData.sliderVal === "" ||
            formData.name === "" ||
            formData.email === "" ||
            formData.contact === "" ||
            formData.address === "" ||
            formData.date === null ||
            formData.date === "" ||
            formData.floor === null ||
            formData.floor === "" ||
            formData.postcode === "" ||
            formData.city === "" ? (
              <button

                disabled
                className="inline-flex justify-center rounded-md border border-transparent bg-[#aaa] py-2 px-4 text-sm font-bold text-white shadow-sm  focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 w-[100%] bg-[#aaa] via-[#620000] to-[#550000]"
                >
                {t("update")}
              </button>
            ) : (
              <button
                onClick={handleInfo}
                className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-bold text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 w-[100%] bg-indigo-400 via-[#620000] to-[#550000]"
                >
                {t("update")}
              </button>
            )}
                    
                    </div>
                  </div>
                </div>
              </div>
            </div>}
          </div>
        </div>
      </form>

   
   </main>

  

 </div>
</div>
);
}

export default EditSentOffer