import React, { useState, useEffect } from 'react';
import {  useNavigate } from "react-router-dom"

import { FaChevronCircleDown } from 'react-icons/fa';
import { FiDownload } from 'react-icons/fi';

import moment from 'moment';
import Select from 'react-select'
import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import config from '../config/config';



import { BsCalendar } from 'react-icons/bs';
import { BsSearch } from 'react-icons/bs';
import HeadBar from '../partials/HeadBar';


import Form from '../partials/Form';
import SingleForm from "../components/SingleForm"






function AddOrder() {

    const [sidebarOpen, setSidebarOpen] = useState(false);
  
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [alter, setAlter] = useState(false)

    
  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

 




  return (
    <div className="flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            {/* Welcome banner */}
            <HeadBar title={"Auftrag hinzufügen"} description={"Fügen Sie eine neue Bestellung von Grund auf hinzu"} />
 
           
            <h2 className='font-bold text-[#0e0e2b] text-[30px] mb-[30px] mt-5 ml-[40px]'>Auftrag hinzufügen</h2>

<Form/>
          </div>
     
        </main>

       

      </div>
    </div>
  );
}

export default AddOrder;