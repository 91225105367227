import React, { useState } from 'react';

const BlogEditor = () => {
  const [title, setTitle] = useState('');
  const [metaTags, setMetaTags] = useState('');
  const [description, setDescription] = useState('');
  const [writerName, setWriterName] = useState('');
  const [paragraphs, setParagraphs] = useState([{ text: '' }]);
  const [descriptions, setDescriptions] = useState([{ text: '' }]);
  const [image, setImage] = useState(null);

  const handleAddParagraph = () => {
    setParagraphs([...paragraphs, { text: '' }]);
  };

  const handleAddDescription = () => {
    setDescriptions([...descriptions, { text: '' }]);
  };

  const handleParagraphChange = (e, index) => {
    const newParagraphs = [...paragraphs];
    newParagraphs[index].text = e.target.value;
    setParagraphs(newParagraphs);
  };

  const handleDescriptionChange = (e, index) => {
    const newDescriptions = [...descriptions];
    newDescriptions[index].text = e.target.value;
    setDescriptions(newDescriptions);
  };

  const handleImageChange = e => {
    setImage(e.target.files[0]);
  };

  return (
    <div className="flex">
      <div className="w-[700px] p-8   bg-white rounded-lg shadow-lg">
        <h1 className="text-3xl font-bold mb-4">Erstellen Sie einen neuen Blog</h1>
        <input
          className="border p-2 mb-4 w-full"
          type="text"
          placeholder="Title"
          value={title}
          onChange={e => setTitle(e.target.value)}
        />
        <input
          className="border p-2 mb-4 w-full"
          type="text"
          placeholder="Meta Tags (separated by commas)"
          value={metaTags}
          onChange={e => setMetaTags(e.target.value)}
        />
        <input
          className="border p-2 mb-4 w-full"
          type="text"
          placeholder="Meta Description"
          value={description}
          onChange={e => setDescription(e.target.value)}
        />
        <input
          className="border p-2 mb-4 w-full"
          type="text"
          placeholder="Writer Name"
          value={writerName}
          onChange={e => setWriterName(e.target.value)}
        />
        {paragraphs.map((paragraph, index) => (
          <textarea
            key={index}
            className="border p-2 mb-4 w-full"
            placeholder="Paragraph"
            value={paragraph.text}
onChange={e => handleParagraphChange(e, index)}
/>
))}
{descriptions.map((description, index) => (
<textarea
key={index}
className="border p-2 mb-4 w-full"
placeholder="Description for Paragraph"
value={description.text}
onChange={e => handleDescriptionChange(e, index)}
/>
))}

<div class="flex items-center justify-center w-full mb-6">
    <label for="dropzone-file" class="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50  hover:bg-gray-100">
  
        <div class="flex flex-col items-center justify-center pt-3 pb-3 mt-3">
            <svg aria-hidden="true" class="w-8 h-8 mb-2 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
            <p class="mb-2 text-sm px-5 text-gray-500 dark:text-gray-400"><span class="font-semibold">Zum Hochladen klicken</span> oder ziehen und ablegen</p>
            <p class="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG oder GIF</p>
        </div>
        <input id="dropzone-file" 
        onChange={handleImageChange}
        type="file"  
        accept="image/png, image/jpeg, image/webp"
        className="hidden" />
    </label>
</div> 

<button  className='w-[100%] rounded px-[30px] mr-[30px] py-[8px] font-semibold text-[18px] text-white bg-blue-500 hover:bg-blue-700'>Save</button>

</div>
<div className="w-[300px] p-8 max-w-sm mx-auto bg-white flex flex-col rounded-lg shadow-lg">
<h1 className="text-xl font-bold mb-4">Elemente hinzufügen</h1>
<button
       className="bg-blue-500 text-white p-2 mb-4 rounded-lg hover:bg-blue-600"
       onClick={handleAddParagraph}
     >
Absatz hinzufügen
</button>
<button
       className="bg-blue-500 text-white p-2 mb-4 rounded-lg hover:bg-blue-600"
       onClick={handleAddDescription}
     >
Beschreibung hinzufügen
</button>
</div>
</div>
);
};

export default BlogEditor;

