import React, { useState, useEffect, useRef } from "react";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import config from "../config/config";
import HeadBar from "../partials/HeadBar";

function ViewRevenue() {
  const [isOpen, setIsOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [user, setUser] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log(localStorage.getItem("viewRevenueId"));
    fetch(
      `${config.baseUrl}/api/sub/user/viewNewReq?user_id=${localStorage.getItem(
        "viewRevenueId"
      )}`
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setUser(data);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleObj = (arr) => {
    const values = arr.map((obj) => obj.value).join(", ");
    console.log("values", values);
    return values;
  };

  function exportCSV() {}
  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 pt-8 w-full max-w-9xl mx-auto">
            <HeadBar
              title={"Umsatzdetails anzeigen"}
              description={"Einzelne Einnahmen für Bestellungen anzeigen"}
              exportCSV={exportCSV}
              isVisible={true}
            />
          </div>

          {loading && (
            <p className="ml-[300px] p-[30px] font-bold text-[30px] text-blue-400">
              Lade Date{" "}
              <img className="inline-block" src="./images/spin.gif" alt="" />
            </p>
          )}

          {!loading && (
            <div className="bg-white w-[90%] h-[100%] p-[25px] shadow-lg m-[30px] border-2 border-grey ml-[40px] ">
              <div className="px-[20px] py-[10px] font-normal text-sm">
                 <label className="text-slate-700 inline-block w-[170px]  font-semibold">
                  ID :{" "}
                </label>
                <span className="">{"EM-" + user._id}</span>
              </div>
              <hr />
              <div className="px-[20px] py-[10px] font-normal text-sm">
                 <label className="text-slate-700 inline-block w-[170px]  font-semibold">
                  Anrede :{" "}
                </label>
                <span className="">{user.salutation}</span>
              </div>
              <hr />
              <div className="px-[20px] py-[10px] font-normal text-sm">
                 <label className="text-slate-700 inline-block w-[170px]  font-semibold">
                  Name :{" "}
                </label>
                <span className="">{user.firstName + " " + user.lastName}</span>
              </div>
              <hr />
              <div className="px-[20px] py-[10px] font-normal text-sm">
                 <label className="text-slate-700 inline-block w-[170px]  font-semibold">
                  Telefon :{" "}
                </label>
                <span className="">{user.phone}</span>
              </div>
              <hr />
              <div className="px-[20px] py-[10px] font-normal text-sm">
                 <label className="text-slate-700 inline-block w-[170px]  font-semibold">
                  Email :{" "}
                </label>
                <span className="">{user.email} </span>
              </div>
              <hr />
              <div className="px-[20px] py-[10px] font-normal text-sm">
                 <label className="text-slate-700 inline-block w-[170px]  font-semibold">
                  Bereich :{" "}
                </label>
                <span className="">{user.area} </span>
              </div>
              <hr />

              <div className="px-[20px] py-[10px] font-normal text-sm">
                 <label className="text-slate-700 inline-block w-[170px]  font-semibold">
                  Objekte :{" "}
                </label>
                <span className="">{handleObj(user.objects)}</span>
              </div>
              <hr />
              <div className="px-[20px] py-[10px] font-normal text-sm">
                 <label className="text-slate-700 inline-block w-[170px]  font-semibold">
                  Ort :{" "}
                </label>
                <span className="">{user.place}</span>
              </div>
              <hr />

              <div className="px-[20px] py-[10px] font-normal text-sm">
                 <label className="text-slate-700 inline-block w-[170px]  font-semibold">
                  Stadt :{" "}
                </label>
                <span className="">{user.location}</span>
              </div>
              <hr />

              <div className="px-[20px] py-[10px] font-normal text-sm">
                 <label className="text-slate-700 inline-block w-[170px]  font-semibold">
                  Straße :{" "}
                </label>
                <span className="">{user.street}</span>
              </div>
              <hr />

              <div className="px-[20px] py-[10px] font-normal text-sm">
                 <label className="text-slate-700 inline-block w-[170px]  font-semibold">
                  Transaktions-ID:{" "}
                </label>

                <span>{user.transIdsub}</span>
              </div>

              <hr />
              <div className="px-[20px] py-[10px] font-normal text-sm">
                 <label className="text-slate-700 inline-block w-[170px]  font-semibold">
                  Bezahlt :{" "}
                </label>
                <span className="">
                  {((user.cost - (user.cost * user.userper) / 100) *
                    user.subper) /
                    100}{" "}
                  €
                </span>
              </div>
              <hr />

              <div className="px-[20px] py-[10px] font-normal text-sm">
                 <label className="text-slate-700 inline-block w-[170px]  font-semibold">
                  Erhalten :{" "}
                </label>
                <span className="">
                  {user.cost - (user.cost * user.userper) / 100} €
                </span>
              </div>

              <hr />
              
            </div>

            
          )}
        </main>
      </div>
    </div>
  );
}

export default ViewRevenue;
