import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next'



import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import config from '../config/config';


import HeadBar from '../partials/HeadBar';

const options = [
  { value: '1', label: 'Oldest' },
  { value: '-1', label: 'Newest' },


]



const ShowQuery = () => {

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [users, setUsers] = useState('')
    const [registeredUsers, setRegisteredUsers] = useState('')
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedOption, setSelectedOption] = useState("");

    const { t } = useTranslation()
const [name, setName] = useState('')
const [email, setEmail] = useState('')
const [phone, setPhone] = useState(null)
const [query, setQuery] = useState('')
const [pin, setPin] = useState('')
const [created, setCreated] = useState('')
const [loading, setLoading] = useState(true)
 

  useEffect(() => {
    
    fetch(`${config.baseUrl}/api/showquery?id=${localStorage.getItem('query_id')}`)
    .then(response => response.json())
    .then((data) =>{
        console.log(data.email)
        setName(data.name)
     
        setEmail(data.email)
        setPhone(data.phone)
        setPin(data.pin)
        setCreated(data.registeredOn)
        setQuery(data.query)
        setLoading(false)
    })
    .catch((error)=> console.log(error))
  },[])

 


  return (
    <div className="flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>

        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">



        <HeadBar title={"Abfrage anzeigen"} description={"Werfen Sie einen Blick auf neue Benutzeranfragen"} />

        

{loading && <p className='ml-[300px] p-[30px] font-bold text-[30px] text-blue-400'>Loading Data <img className='inline-block' src="./images/spin.gif" alt="" /></p>
}
{!loading && 
<div className='bg-white w-[90%] h-[100%] p-[25px] shadow-lg m-[30px] border-2 border-grey '>
<div className='px-[20px] py-[10px]'>




<label className='text-[#33333] font-bold '>{t('name')} : </label>
<span className=''>{name}</span>
</div>
<hr />
<div className='px-[20px] py-[10px]'>
<label className='text-[#33333] font-bold '>{t('phone')} : </label>
<span>{phone}</span>
</div>
<hr />
<div className='px-[20px] py-[10px]'>
<label className='text-[#33333] font-bold '>{t('email')} : </label>
<span>{email}</span>
</div>
<hr />
<div className='px-[20px] py-[10px]'>
<label className='text-[#33333] font-bold '>Postleitzahl : </label>
<span>{pin}</span>
</div>


<hr />
<div className='px-[20px] py-[10px]'>
<label className='text-[#33333] font-bold '>{t('query')} : </label>
<span>{query}</span>
</div>

<hr />
<div className='px-[20px] py-[10px]'>
<label className='text-[#33333] font-bold '>Registered On : </label>
<span>{created}</span>
</div>

<hr />
</div> }
</div>
        </main>

       

      </div>
    </div>
  );
}


export default ShowQuery

