import { useState, useRef, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import config from "../config/config";
const AcceptTnC = () => {
  const [sub, setSub] = useState({});
  const [orderId, setOrderId] = useState("");
  const [subTokenId, setSubTokenId] = useState("");
  const checkboxRef = useRef(null);
  const btnRef = useRef(null);



  const [btnSatate, setBtnState] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const handleButtonClick = () => {
    checkboxRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    if (event.target.checked) {
      btnRef.current.scrollIntoView({ behavior: "smooth" });

      setBtnState(true);
    } else {
      setBtnState(false);
    }
  };

  

  useEffect(() => {
    fetch(
      `${config.baseUrl}/api/sub/profile/read?id=${localStorage.getItem(
        "em_sub_id"
      )}`
    )
      .then((response) => response.json())
      .then((data) => {
        setSub(data.sub);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <>
    <div className="bg-indigo-600 w-full h-2"></div>
    <img className="h-8 mt-8 ml-8"  src="https://entsorg-meistet-bucket.fra1.digitaloceanspaces.com/em%2Flogo.png" alt="" />
      <h1 ref={btnRef} className=" text-center pt-16 mb-16 text-3xl tracking-tight font-extrabold  text-blue-500">
      
      Nutzungsbedingungen Akzeptieren
      </h1>

      <div className="flex max-w-[1200px] items-start mx-auto ">
        <div className="px-[20px] py-8  bg-gray-100 rounded box flex max-h-[100%] flex-col lg:mt-[0px] mt-[50px] max-w-[500px] justify-start mx-[auto] border-2 border-slate-200 ">
          <h2 className="mb-4 text-2xl font-extrabold  text-gray-700">
            Bestelldetails
          </h2>

          <h3
            
            className="mb-1 text-lg font-semibold  text-slate-700"
          >
            Beschreibung
          </h3>

          <p className="text-slate-800 inline-block text-sm mb-5">
            *Beschreibung unserer Dienstleistungen und weitere Textinhalte*
          </p>

          <div className="mb-3 text-sm">
            <span className="text-slate-700 inline-block w-[160px]">
              Auftragsnummer
            </span>
            <span className="text-slate-800 w-[230px] inline-block">
              {sub._id}
            </span>
          </div>
          <span className="w-[90%] bg-gray-100 h-[1px]"></span>
          <div className="mb-3 text-sm">
            <span className="text-slate-700 inline-block w-[160px]">
              Vollständiger Name{" "}
            </span>
            <span className="text-slate-800 w-[230px] inline-block">
              {sub.firstName + " " + sub.lastName}
            </span>
          </div>
          <span className="w-[90%] bg-gray-100 h-[1px]"></span>

          <div className="mb-3 text-sm">
            <span className="text-slate-700 inline-block w-[160px]">
              Telefon
            </span>
            <span className="text-slate-800  w-[230px] inline-block">
              {sub.phone}
            </span>
          </div>

          <span className="w-[90%] bg-gray-100 h-[1px]"></span>

          <div className="mb-3 text-sm">
            <span className="text-slate-700 inline-block w-[160px]">
              Email{" "}
            </span>
            <span className="text-slate-800 w-[230px] inline-block">
              {sub.email}
            </span>
          </div>
          <span className="w-[90%] bg-gray-100 h-[1px]"></span>

          <div className="mb-3 text-sm">
            <span className="text-slate-700 inline-block w-[160px]">
              Service
            </span>
            <span className="text-slate-800 w-[230px] inline-block">
              Entsorg Meister-Plattform
            </span>
          </div>
          <span className="w-[90%] bg-gray-100 h-[1px]"></span>

          <div className="mb-3 text-sm">
            <span className="text-slate-700 inline-block w-[160px]">
              Standort{" "}
            </span>
            <span className="text-slate-800 w-[230px] inline-block">
              {sub.location}
            </span>
          </div>

          <span className="w-[90%] bg-gray-100 h-[1px]"></span>

          <div className="mb-3 text-sm">
            <span className="text-slate-700 inline-block w-[160px]">
              Postleitzahl{" "}
            </span>
            <span className="text-slate-800 w-[230px] inline-block">
              {sub.postcode}
            </span>
          </div>

          <span className="w-[90%] bg-gray-100 h-[1px]"></span>

          <div className="mb-3 text-sm">
            <span className="text-slate-700 inline-block w-[160px]">
              Straße{" "}
            </span>
            <span className="text-slate-800 w-[230px] inline-block">
              {sub.street}
            </span>
          </div>
          <span className="w-[90%] bg-gray-100 h-[1px]"></span>

        
          <div className="mb-3 text-sm">
            <span className="text-slate-700 inline-block w-[160px]">
              Bezahlt werden
            </span>
            <span className="text-slate-800 w-[230px] inline-block">{localStorage.getItem("to__pay")} €</span>
          </div>
          <span className="w-[90%] bg-gray-100 h-[1px]"></span>

          {!btnSatate ? (
            <button
              onClick={handleButtonClick}
              type="button"
              className="mt-5 text-sm  hover:translate-y-[2px] transition  object-contain px-6 py-2.5 font-semibold text-white bg-[#676aee] border-b-4 border-blue-800 rounded shadow-indigo-500 shadow-sm hover:border-blue-600"
            >
              Nutzungsbedingungen akzeptieren
            </button>
          ) : (

            <NavLink
            to={`/payment?tokenId=${
              localStorage.getItem("subToken__Id")
            }&subId=${localStorage.getItem(
              "em_sub_id"
            )}&id=${localStorage.getItem("order__id")}`}
              className="mt-5 text-sm  hover:translate-y-[2px] transition  object-contain px-6 py-2.5 font-semibold text-center text-white bg-[#676aee] border-b-4 border-blue-800 rounded shadow-indigo-500 shadow-sm hover:border-blue-600"
            >
             Weiter zur Zahlung
            </NavLink>
          )}
        </div>
        <div className="px-[20px] py-8 bg-gray-100 mb-32 rounded box flex flex-col lg:mt-[0px] mt-[50px] max-w-[600px] justify-start mx-[auto] border-2 border-slate-200">
          <h2 className="mb-4 text-2xl font-extrabold  text-gray-800">
          Allgemeine Geschäftsbedingungen der Invent A UG -
entsorg-meister.de
          </h2>
          <p className=" text-sm mb-2">Stand: 22.02.2022
</p>

          <ul class="space-y-4 text-gray-600 list-disc list-inside">
            <li>
            Allgemeines
              <ol class="pl-5 mt-2 space-y-1 list-decimal list-inside">
                <li>
                Diese Geschäftsbedingungen gelten für alle Verträge,
Lieferungen und sonstigen Leistungen der Invent A UG,
Josef-Obenhin-Straße 5, 80634 München, (im Folgenden:
„Entsorg-Meister“), auch bezüglich des Internetportals
https://www.entsorg-meister.de nebst aller zugehörigen
Sub-Domains und für alle Bestellungen von Kunden bei
Entsorg-Meister. Von diesen Bedingungen abweichende
Regelungen der Kunden gelten nicht, es sei denn, Entsorg-Meister
hat dies schriftlich bestätigt. Individuelle Abreden zwischen
Entsorg-Meister und den Kunden haben stets Vorrang vor diesen
Geschäftsbedingungen.
                </li>
                <li>
                 Verträge zwischen Entsorg-Meister und den Kunden unterliegen
dem materiellen Recht der Bundesrepublik Deutschland unter
Ausschluss des UN Kaufrechts und den Normen des internationalen
Privatrechts. Diese Rechtswahl gilt gegenüber Verbrauchern (§ 13
BGB) nur, sofern nicht zwingende Bestimmungen des Rechts des
Staates, in dem der Verbraucher seinen gewöhnlichen Aufenthalt
hat, die Geltung eines abweichenden Rechts vorschreibt.
                </li>
                <li>
                Ist der Kunde Kaufmann oder eine juristische Person des
öffentlichen Rechts oder öffentlich-rechtliches Sondervermögen, so
wird als Gerichtsstand Kiel, Deutschland vereinbart. Das gleiche gilt,
sofern der Kunde keinen allgemeinen Gerichtsstand in Deutschland
hat oder der Wohnsitz oder gewöhnlichen Aufenthalt im Zeitpunkt
der Klageerhebung nicht bekannt sind.
                </li>
              </ol>
            </li>
            <li>
            Vertragsinhalte und Informationen zum Vertragsschluss

              <ul class="pl-5 mt-2 space-y-1 list-decimal list-inside">
                <li>
                Entsorg-Meister bietet auf dem Portal www.entsorg-meister.com
Vermittlungs- und Transferleistungen zwischen Personen, die eine
Immobilie bzw. Räumlichkeit von Abfall, Sperrmüll und/oder
sonstigen Gegenständen befreien lassen wollen (im Folgenden:
„Kunden“) und Entsorgungsdienstleistern, die diese Dienstleistung
für den Kunden durchführen wollen (im Folgenden:
„Entsorgungsdienstleister“). Gegenstand der Leistung durch
Entsorg-Meister ist die Vermittlung und der stellvertretende
Abschluss von Entsorgungsverträgen zwischen dem Kunden und
dem Entsorgungsdienstleister. Die Dienstleistung von
Entsorg-Meister beschränkt sich auf die reine Vermittlung / den
stellvertretenden Abschluss eines Entsorgungsvertrages zwischen
dem Kunden und dem Entsorgungsdienstleister nebst
Transferleistungen in Form der Annahme von Anzahlungen des
Kunden für den Entsorgungsdienstleister. Entsorg-Meister wird
selbst nicht Partei des Entsorgungsvertrages und wird aus diesem
weder berechtigt, noch verpflichtet.
                </li>

                <li>
                Mit den Angaben des Kunden über die Art der
Entsorgungsräumlichkeit, den Grad der Verschmutzung und den
gewünschten Zeitpunkt für die Entsorgungsdienstleistung im
Entsorg-Meister-Rechner ermittelt Entsorg-Meister den Preis für die
Entsorgungsdienstleistung. Der Kunde erhält sodann eine E-Mail
von Entsorg-Meister. Über einen Klick auf den Button „Angebot“,
wird der Kunde auf eine Webseite weitergeleitet, auf der ihm das
Angebot einschließlich des Preises für die gewünschten Leistungen
angezeigt wird. Mittels des Betätigens des Buttons „Kostenpflichtig
Buchen“, kommt zwischen Entsorg-Meister und dem Kunden ein
Vermittlungsvertrag zustande. Im Rahmen dieses
Vermittlungsvertrages wird Entsorg-Meister anhand der vom
Kunden eingegebenen Daten sodann im Namen und in Vollmacht
des Kunden einen Entsorgungsdienstleister aus dem Netzwerk von
Entsorg-Meister aussuchen und diesem stellvertretend, für den
Kunden den Abschluss eines Entsorgungsvertrages zwischen dem Kunden und dem Entsorgungsdienstleister anbieten. Mit Annahme
des Angebots durch den Entsorgungsdienstleister, welche
stellvertretend für den Kunden gegenüber Entsorg-Meister erfolgt,
entsteht ein Entsorgungsvertrag zwischen dem Kunden und dem
Entsorgungsdienstleister zu den genannten Konditionen aus der
ursprünglichen Eingabe des Kunden im Entsorg-Meister-Rechner
nebst den Maßgaben nach diesen AGB. Entsorg-Meister teilt dem
Kunden sodann den erfolgreichen Abschluss des
Entsorgungsvertrages nebst weiteren relevanten Daten mit.

                </li>

                <li>Der Vertragstext wird von Entsorg-Meister gespeichert und kann
von dem Kunden auf Anfrage per E-Mail an info@entsorg-meister.de
angefordert werden. Im Zuge dessen wird Entsorg-Meister den
Vertragstext dem Kunden zuschicken.
</li>

<li> Die Vertragssprache ist deutsch.
</li>
<li>Im Zuge des Eingabeprozesses im Entsorg-Meister-Rechner,
kann der Kunde vor Übersendung der Informationen die Eingaben
abschließend überprüfen und etwaige Eingabefehler in den
entsprechenden Feldern korrigieren. Hierfür kann der Kunde den
„zurück“ und „weiter“ Button nutzen, um die Angaben zu korrigieren.
Sollte der Kunde seine angegebenen Daten direkt vor Abschluss des
Vertrages ändern/korrigieren wollen, ist ein Abbruch des
Bestellprozesses und eine Kontaktaufnahme mit Entsorg-Meister
erforderlich. Die Änderungen der Daten hat Einfluss auf das
Angebot, sodass ein entsprechend neues Angebot mit den
korrigierten Daten erstellt werden muss.</li>

              </ul>
            </li>
            <li>
            Mitwirkungspflichten des Kunden
                          <ul class="pl-5 mt-2 space-y-1 list-decimal list-inside">
                <li>
                Der Kunde wird für geeignete Zufahrtsmöglichkeiten von
Entsorgungsfahrzeugen von bis zu 10 t in unmittelbarer Nähe zum
Leistungsort bzw. befestigte Stellplätze für die Aufstellung von
Containern sorgen. Falls dies nicht möglich ist, wird er den
Entsorgungsdienstleister vor Vertragsschluss auf Umstände hinweisen, die die Zufahrtsmöglichkeiten oder Stellplätze behindern
könnten. Für den vorgenannten Hinweis soll der Kunde die
Bestellnotiz Entsorg-Meister-Rechner benutzen. Eine Umstellung
des Containers vom Aufstellplatz durch den Kunden – auch nur für
kurze Zeit – ist untersagt.


                </li>

                <li>Der Kunde wird den Zustand der Räumlichkeit und des Umfangs
der Entsorgungsleistung, insbesondere hinsichtlich Größe und
Verschmutzungsgrad, nach bestem Wissen und Gewissen angeben.
Der Kunde prüft die zu entsorgenden Gegenstände vor
Beauftragung auf das Vorhandensein von gefährlichen Stoffe
und/oder verbotenen Gegenständen und informiert die
Vertragspartner hierüber vor Beauftragung.
</li>


<li>Der Kunde wird dem Entsorgungsdienstleister bzw. dessen
Erfüllungsgehilfen zum angegeben Termin Zugang zu den
Räumlichkeiten verschaffen.</li>

<li>Bei der Aufstellung von Containern auf öffentlichen
Verkehrsflächen hat der Kunde die Straßenverkehrsordnung
einzuhalten und ggf. erforderliche behördliche Genehmigungen
einzuholen sowie für die notwendige Verkehrssicherung
(Beleuchtung, Absperrung, etc.) zu sorgen.</li>

<li>Zu entsorgende Gegenstände müssen von Strom- und
Wasserleitungen getrennt sein. Anderenfalls können Zusatzkosten
zulasten des Kunden anfallen, oder die Entsorgung der
entsprechenden Gegenstände durch den Entsorgungsdienstleister
oder seine Erfüllungsgehilfen verweigert werden.
</li>

<li>Der Kunde wird dem Entsorgungsdienstleister den Abschluss
der Arbeiten quittieren.</li>

<li>Der Kunde wird alle erforderlichen Mitwirkungshandlungen
rechtzeitig erledigen, insbesondere im Falle seiner
Nichterreichbarkeit einen bevollmächtigten Vertreter für die Unterzeichnung von für die Entsorgung und Abtransport
erforderlichen Fahraufträge, Begleitscheine, Wiegenoten, etc.,
bereitstellen.</li>
              </ul>
            </li>
            <li>
            Eigentumsaufgabe / Gefährliche Stoffe / Freistellung
              <ul class="pl-5 mt-2 space-y-1 list-decimal list-inside">
                <li>
                Der Kunde sichert zu, dass er die uneingeschränkte
Verfügungsberechtigung über die zu entsorgenden Gegenstände
besitzt. Während der Durchführung des Entsorgungsvertrages gibt
der Kunde das Eigentum und den Besitz an allen vom
Leistungsumfang des Entsorgungsvertrages umfassten
Gegenständen auf (§ 959 BGB), sodass diese herrenlos werden und
in das Eigentum des Entsorgungsdienstleisters oder Dritter
übergehen können. Die tatsächliche Aufgabe geschieht konkludent,
also durch schlüssiges Verhalten, indem der Kunde die Entfernung
aus der Räumlichkeit duldet. Unabhängig von der Duldung und einer
Entfernung aus der Räumlichkeit gilt dies nicht für gefährliche
Abfälle, Stoffe, Materialien sowie Öle und Fette und weitere
grundwasserschädigende Stoffe, die im Sinne des
Kreislaufwirtschaftsgesetzes KrWG beseitigt werden müssen und
die nicht Vertragsgegenstand werden. Sofern sich im Nachgang der
Entsorgungsleistung herausstellt, dass solche Stoffe mit entfernt
wurden, informiert der Entsorgungsdienstleister den Kunden
hierüber. Der Kunde ist sodann verpflichtet, eine ordnungsgemäße
Entsorgung sicherzustellen und den Entsorgungsdienstleister von
Ansprüchen Dritter bzgl. dieser gefährlichen Stoffe freizustellen.

                </li>

                <li>Absatz 1 gilt entsprechend für verbotene und gefährliche
Gegenstände. Abweichend von Absatz 1 ist der
Entsorgungsdienstleister jedoch berechtigt, hinsichtlich verbotener
oder gefährlicher Gegenstände, wie etwa Waffen- oder
Munitionsteile, eine Herausgabe an den Kunden zu verweigern und
diese stattdessen an die zuständige Behörde zu übergeben.
</li>

<li>Zusätzlichen Aufwand für Maßnahmen nach Absatz 1 und/oder
2 oder die durch wesentliche Verstöße des Kunden gegen seine
Mitwirkungspflichten nach § 3 erforderlich werden, hat der Kunde,
sofern er dies zu vertreten hat, dem Entsorgungsdienstleister nach
den gesetzlichen Vorschriften zu ersetzen.
</li>

              </ul>
            </li>

            <li>
            Entsorgungsdienstleistung / Vertragsanpassung / Rücktritt /
Schadensersatz
              <ul class="pl-5 mt-2 space-y-1 list-decimal list-inside">
                <li>
                Der Entsorgungsdienstleister wird die zu entsorgenden
Gegenstände aus den Räumlichkeiten entfernen und diese
besenrein hinterlassen. Unbeschadet § 4 wird der
Entsorgungsdienstleister die Entsorgungsleistungen gemäß
Leistungsumfang sach- und fachgerecht und nach dem jeweiligen
Stand der Technik und den gesetzlichen Regelungen zum
Abfallrecht, insbesondere dem Kreislaufwirtschaftsgesetz (KrWG)
und den zugehörigen Verordnungen durchführen. Grundlage des
Entsorgungsvertrages sind die vom Kunden angegebenen
Konditionen im Entsorg-Meister-Rechner. Sofern der tatsächliche
Zustand der Räumlichkeit einen wesentlich größeren Umfang als
den ursprünglich vom Kunden angegebenen Umfang darstellt, ist
der Entsorgungsdienstleister nach seiner Wahl berechtigt, entweder
nur jenen Umfang an Entsorgungsdienstleitungen zu erbringen, wie
er ursprünglich vereinbart war oder dem Kunden den nötigen
Mehraufwand in Rechnung zu stellen. Der Entsorgungsdienstleister
wird den Kunden vor dem Entstehen auf den voraussichtlichen
Mehraufwand hinweisen.
                </li>

                <li>Sofern sich herausstellt, dass aufgrund der unzutreffenden
Angaben des Kunden im Entsorg-Meister-Rechner eine
Durchführung der Entsorgungsdienstleistung nicht möglich ist oder
mit erheblichem Mehraufwand verbunden ist, ist der
Entsorgungsdienstleister berechtigt, vom Entsorgungsvertrag
zurückzutreten. Sofern der Kunde diesen Rücktrittsgrund zu
vertreten hat, ist der Entsorgungsdienstleister berechtigt, 10% des ursprünglich vereinbarten Entgelts als pauschalen
Schadensersatzes zu fordern. Der Schadensersatz ist höher oder
niedriger anzusetzen, wenn der Entsorgungsdienstleister einen
höheren Schaden nachweist oder der Kunde nachweist, dass ein
Schaden überhaupt nicht entstanden oder wesentlich niedriger als
die Pauschale ist.</li>

<li>Wesentliche Verstöße des Kunden gegen seine
Mitwirkungspflichten gemäß § 3 berechtigen den
Entsorgungsdienstleister ebenfalls zum Rücktritt.</li>

<li>Die gesetzlichen Rücktrittsrechte bleiben von den vorgenannten
Regelungen unberührt.</li>
              </ul>
            </li>
            <li>
            Preise und Zahlungsbedingungen              <ul class="pl-5 mt-2 space-y-1 list-decimal list-inside">
                <li>
                Bei Bestellungen gelten die Angebot zum Zeitpunkt der
Bestellung angegebenen Preise. Sämtliche Preise sind
Gesamtpreise und beinhalten die gesetzliche Umsatzsteuer. Die
angegebenen Preise sind jene Preise, die Gegenstand des
Entsorgungsvertrages werden. Für die Nutzung des
Entsorg-Meister-Rechner entstehen dem Kunden keine Kosten.
                </li>

                <li>Nach erfolgter Bestellung ist eine Anzahlung (=Vorauszahlung)
für den zu beauftragenden Entsorgungsdienstleister durch den
Kunden in der jeweils vor Vertragsschluss angegebenen Höhe fällig.
Die Anzahlung ist an Entsorg-Meister zu leisten. Entsorg-Meister
nimmt diese Anzahlung für den zu beauftragenden
Entsorgungsdienstleister an.</li>

<li>Als Zahlungsmittel stehen dem Kunden die vor Vertragsschluss
auszuwählenden Optionen zur Verfügung. Entsorg-Meister weist
darauf hin, dass die Auswahl des jeweiligen Zahlungsmittels
zusätzlich die Geltung der AGB des jeweiligen
Zahlungsdienstleisters zur Folge haben kann.
</li>

<li>Mit Beauftragung wird Entsorg-Meister versuchen einen
Entsorgungsdienstleister nach den Maßgaben dieser AGB
beauftragen.</li>
              </ul>
            </li>
            <li>
            Gesetzliches Widerrufsrecht
              <ul class="pl-5 mt-2 space-y-1 list-decimal list-inside">
                <li>
                Bei Fernabsatzverträgen i.S.d. § 312c BGB und bei „Außerhalb von
Geschäftsräumen geschlossenen Verträgen“ i.S.d. § 312b BGB
gelten für Verbraucher (jede natürliche Person, die ein
Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder
ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit
zugerechnet werden können.) mit Wirkung auch für den vermittelten
Entsorgungsvertrag folgende gesetzlichen Widerrufsrechte:
<br />
Widerrufsrecht
<br />
Invent A UG, Josef-Obenhin-Straße, 80634 München, E-Mail:
info@entsorg-meister.de

<br />
Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von
Gründen diesen Vertrag zu widerrufen. Die Widerrufsfrist beträgt
vierzehn Tage ab dem Tag des Vertragsschlusses. Um Ihr
Widerrufsrecht auszuüben, müssen Sie uns (Invent A UG,
Josef-Obenhin-Straße, 80634 München, E-Mail:
info@entsorg-meister.de) mittels einer eindeutigen Erklärung (z.B.
ein mit der Post versandter Brief, Telefax oder E-Mail) über Ihren
Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können
dafür das beigefügte Muster-Widerrufsformular verwenden, das
jedoch nicht vorgeschrieben ist. Zur Wahrung der Widerrufsfrist
reicht es aus, dass Sie die Mitteilung über die Ausübung des
Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
<br />
Folgen des Widerrufs
<br />
Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle
Zahlungen, die wir von Ihnen erhalten haben, einschließlich der
Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von
uns angebotene, günstigste Standardlieferung gewählt haben),
unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag
zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses
Vertrags bei uns eingegangen ist. Für diese Rückzahlung
verwenden wir dasselbe Zahlungsmittel, das Sie bei der
ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen
wurde ausdrücklich etwas anderes vereinbart; in keinem Fall
werden Ihnen wegen dieser Rückzahlung Entgelte berechnet. Haben
Sie verlangt, dass die Dienstleistungen während der Widerrufsfrist
beginnen sollen, so haben Sie uns einen angemessenen Betrag zu
zahlen, der dem Anteil der bis zu dem Zeitpunkt, zu dem Sie uns von
der Ausübung des Widerrufsrechts hinsichtlich dieses Vertrags
unterrichten, bereits erbrachten Dienstleistungen im Vergleich zum
Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen
entspricht.
<br />
Muster-Widerrufsformular
<br />

(Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte
dieses Formular aus und senden Sie es zurück.)
<br />
An
<br />
Invent A UG

<br />
Josef-Obenhin-Straße 5
<br />
80634 München,
<br />
Deutschland
<br />
E-Mail-Adresse: info@entsorg-meister.de
<br />
– Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*)
abgeschlossenen Vertrag über die Erbringung der folgenden
Dienstleistungen________________________________
<br />
Bestellt am (*)/erhalten am (*)
<hr />
<br />
Name des/der Verbraucher(s)
<br />
Anschrift des/der Verbraucher(s)
<hr />
___
Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)
<br />
<hr />

Datum
<br />
—————————————
<br />
(*) Unzutreffendes streichen.


                </li>

                
              </ul>
            </li>
            <li>
            Rücktritt vom Vermittlungsvertrag / Leistungshindernisse
              <ol class="pl-5 mt-2 space-y-1 list-decimal list-inside">
                <li>
                Sofern es Entsorg-Meister nicht möglich ist, zu den vom Kunden
genannten Konditionen für den Kunden einen Entsorgungsvertrag
mit einem Entsorgungsdienstleister aus dem Netzwerk von
Entsorg-Meister zu schließen, sind der Kunde und Entsorg-Meister
zum Rücktritt vom Vermittlungsvertrag berechtigt. Etwaig bereits
geleistete Anzahlungen erstattet Entsorg-Meister dem Kunden
binnen sieben Werktagen. Entsprechendes gilt, wenn sich
herausstellt, dass der Vermittlungs- und/oder Entsorgungsvertrag
unwirksam oder nichtig war.
                </li>
              </ol>
            </li>
            <li>
            Haftungsausschluss
              <ul class="pl-5 mt-2 space-y-1 list-decimal list-inside">
                <li>
                Außerhalb der Haftung für Sach- und Rechtsmängel haftet
Entsorg-Meister unbeschränkt, soweit die Schadensursache auf
Vorsatz oder grober Fahrlässigkeit beruht. Entsorg-Meister haftet auch für leicht fahrlässige Verletzungen von wesentlichen Pflichten
(Pflichten, deren Verletzung die Erreichung des Vertragszwecks
gefährdet) sowie für die Verletzung von Kardinalpflichten (Pflichten,
deren Erfüllung die ordnungsgemäße Durchführung des Vertrages
überhaupt erst ermöglicht und auf deren Einhaltung der Kunde
regelmäßig vertraut und vertrauen darf), jedoch jeweils nur für den
vorhersehbaren, vertragstypischen Schaden. Für die leicht
fahrlässige Verletzung anderer als der vorstehenden Pflichten
haftet Entsorg-Meister nicht.
                </li>

                <li>
                 Die Haftungsbeschränkungen des Absatzes 1 gelten nicht bei
Verletzungen von Leben, Körper und Gesundheit, für einen Mangel
nach Übernahme einer Garantie für die Beschaffenheit des
Produktes und bei arglistig verschwiegenen Mängeln. Die Haftung
nach dem Produkthaftungsgesetz bleibt unberührt.
                </li>

                <li>
                Ist die Haftung Entsorg-Meister ausgeschlossen oder
beschränkt, so gilt dies ebenfalls für die persönliche Haftung ihrer
Angestellten, Vertreter und Erfüllungsgehilfen.

                </li>
              </ul>
            </li>
            <li>
            Datenschutz
                          <ul class="pl-5 mt-2 space-y-1 list-decimal list-inside">
                <li>
                Zur Abwicklung des Vertrags werden die erforderlichen
personenbezogenen Daten von Entsorg-Meister erhoben und
verarbeitet. Diese Datenverarbeitung erfolgt auf der Grundlage von
Art. 6 Abs. 1 b) DSGVO zur Erfüllung des geschlossenen Vertrages
sowie durch Durchführung vorvertraglicher Maßnahmen. Die
gespeicherten personenbezogenen Daten werden von
Entsorg-Meister vertraulich behandelt. Bei der Erhebung,
Verarbeitung und Nutzung der personenbezogenen Daten des
Kunden werden die Vorschriften des Bundesdatenschutzgesetzes
(BDSG) und des der EU-Datenschutzgrundverordnung (DSGVO)
beachtet und eingehalten. Nähere Erläuterungen zum Datenschutz
durch Entsorg-Meister finden Sie in den allgemeinen
Datenschutzhinweisen unterliegen
<br />
<br />
https://entsorg-meister.de/datenschutzerklaerung/. Eine Löschung
der Daten erfolgt nach Abschluss der Vertragsdurchführung und
nach dem Ende eventueller weiterer gesetzlicher
Aufbewahrungspflichten.

                </li>
              </ul>
            </li>
            <li>
            Alternative Streitbeilegung
                          <ul class="pl-5 mt-2 space-y-1 list-decimal list-inside">
                <li>
                Die Europäische Kommission stellt eine Plattform zur
Online-Streitbeilegung (OS) bereit. Dieses Portal finden Sie hier:
http://ec.europa.eu/consumers/odr/. Die für eine Streitbeilegung
erforderliche Angabe unserer E-Mail-Adresse lautet
info@awl-zentrum.de. Wir sind nicht bereit, an einem solchen
Schlichtungsverfahren teilzunehmen und sind hierzu auch nicht
verpflichtet.
                </li>
              </ul>
            </li>

            <li>
              Leistungen
              <ul class="pl-5 mt-2 space-y-1 list-decimal list-inside">
                <li>
                  Entsorg-meister" bietet Dienstleistungen zur Entfernung und
                  Entsorgung von unerwünschten Gegenständen an. Dies umfasst die
                  Abholung von Gegenständen von einem vereinbarten Ort sowie die
                  sichere Entsorgung oder Wiederverwendung der Gegenstände.
                </li>
              </ul>
            </li>
            <li>
              Buchung von Dienstleistungen
              <ul class="pl-5 mt-2 space-y-1 list-decimal list-inside">
                <li>
                  Die Buchung von Dienstleistungen von "Entsorg-meister" kann
                  telefonisch oder über die Website des Unternehmens erfolgen.
                  Bei der Buchung müssen alle relevanten Informationen,
                  einschließlich des Umfangs der Dienstleistungen und des
                  Abholorts, angegeben werden.
                </li>
              </ul>
            </li>
            <li>
              Preise und Zahlung
              <ul class="pl-5 mt-2 space-y-1 list-decimal list-inside">
                <li>
                  Die Preise für die Dienstleistungen von "Entsorg-meister"
                  richten sich nach dem Umfang der Dienstleistungen und werden
                  vor der Buchung mitgeteilt. Die Zahlung kann online oder bei
                  der Abholung erfolgen. "Entsorg-meister" behält sich das Recht
                  vor, im Falle von zusätzlichen Anforderungen oder
                  Unvorhergesehenem zusätzliche Gebühren zu erheben.
                </li>
              </ul>
            </li>

            <div class="flex items-start">
              <div class="flex items-center h-5">
                <input
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  ref={checkboxRef}
                  id="terms"
                  aria-describedby="terms"
                  type="checkbox"
                  class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                  required=""
                />
              </div>
              <div class="ml-3 text-sm">
                <label for="terms" class="font-medium text-blue-700 ">
                Ich akzeptiere die allgemeinen {" "}
                  <a class="font-semibold text-primary-600 underline dark:text-primary-500">
                  Geschäftsbedingungen
                  </a>
                </label>
              </div>
            </div>
          </ul>
        </div>
      </div>
    </>
  );
};

export default AcceptTnC;
