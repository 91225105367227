import React, { useState, useEffect } from 'react';
import { FiDownload } from 'react-icons/fi';


const Base64PDFViewer = ({ base64Data }) => {

  const [pdfSrc, setPdfSrc] = useState('');

  // Convert base64 data to blob URL
  const convertBase64ToBlobURL = (base64Data) => {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: 'application/pdf' });
    return URL.createObjectURL(blob);
  };

  // Handle base64 data changes
  useEffect(() => {
    const blobUrl = convertBase64ToBlobURL(base64Data);
    setPdfSrc(blobUrl);

    return () => {
      URL.revokeObjectURL(blobUrl);
    };
  }, [base64Data]);

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = pdfSrc;
    link.download = 'file.pdf';
    link.click();
  };

  return (
    <div>
                  <div className="bg-white font-bold text-slate-800 text-[26px] border py-3 px-3 shadow-sm rounded-sm">

                    <button
                onClick={handleDownload}
                type="button"
                className="mb-2 text-sm ml-3 hover:translate-y-[2px] transition  object-contain px-6 py-1.5 font-semibold text-white bg-[#676aee] border-b-4 border-blue-800 rounded shadow-indigo-500 shadow-sm hover:border-blue-600"
              >
                <FiDownload className="text-white text-[18px] mr-2 mb-0.75 inline-block" />{" "}
                Rechnung exportieren
              </button>
              </div>
              <object data={pdfSrc} type="application/pdf" width="100%" height="1000px">
        <p>This browser does not support PDF viewing. You can download the file using the button below.</p>
      </object>
    </div>
  );
};

export default Base64PDFViewer;
