import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next'



const EditSub = () => {
  const { t } = useTranslation()

  const goToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
    
    const interval = setTimeout(() => {
        setSuccess('')
     
       }, 5000);

       return () => clearInterval(interval);

  };
  const [success, setSuccess] = useState('')
  const [loading, setLoading] = useState(true)
  const [formData, setFormData] = useState({
    
    name: '',
    id: '',
    company: '',
    phone:'',
    email: '',
    postcode: '',
    location: '',
    street: '',
    radius: null,
    vatId: ''
});   
const navigate = useNavigate()
const id=localStorage.getItem('editSub_id')
 
  useEffect(() => {
    fetch(`http://localhost:3002/api/editSub?id=${localStorage.getItem('editSub_id')}`)
    .then(response => response.json())
    .then((data) =>{
        console.log(data)
      setFormData({...formData,
        name: data.name,
        id: data._id,
        company: data.company,
        phone:data.phone,
        email: data.email,
        postcode: data.postcode,
        location: data.location,
        street: data.street,
        radius: data.radius,
        vatId: data.vat,
        
       })
       setLoading(false)

    })
    .catch((error)=> console.log(error))
  },[])

  

  async function handleSubmit(event) { 
    event.preventDefault()
}


async function handleInfo(event) { 
    const response = await fetch("http://localhost:3002/api/updateSubInfo", {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: formData.id,
        name: formData.name,
        company: formData.company,
        phone:formData.phone,
        email: formData.email,
        postcode: formData.postcode,
        location: formData.location,
        street: formData.street,
        radius: formData.radius,
        vatId: formData.vatId
      }),
    })

    const data = await response.json()

    if (data.status === 'ok') {
        goToTop()
        setSuccess("success")
      }
      else{
        goToTop()
        setSuccess("error")
      }

  }
 
  
  
  return (
    
    <section className="main overflow-y-auto ml-[300px] z-0 top-0 w-[calc(100vw-300px)] pt-[30px] pb-[100px] right-0 mt-[70px] min-h-[calc(100vh-70px)] bg-white">

    <h2 className='font-bold text-[#0e0e2b] text-[30px] mb-[30px] ml-[120px]'>Edit Subcontractor Info</h2>
    <form  onSubmit={handleSubmit}>
   
      <div className=' ml-[120px] w-[90vw] max-w-[1000px] rounded text-[#333]  flex justify-start  p-[45px] bg-white shadow-lg border-2 border-grey '>
        <div>
        {loading && <p className='ml-[300px] p-[30px] font-bold text-[30px] text-blue-400'>{t('loading-data')} <img className='inline-block' src="./images/spin.gif" alt="" /></p>
}
   {!loading &&   
   <div className='w-[90vw]  max-w-[900px]'>
         {success==='success' && <div className='mb-[20px]'>
  <p className='bg-blue-100 border-[1px] border-solid border-blue-500 w-[100%] px-[30px] py-[10px] '>Subcontractor Information updated successfully</p>
</div>}
{success==='error' && <div className='mb-[20px]'>
  <p className='bg-red-100 border-[1px] border-solid border-red-500 w-[100%] px-[30px] py-[10px] '>An error occured</p>
</div>}
     <div>

        
          <div className=" sm:overflow-hidden sm:rounded-md">
            <div className="space-y-6 bg-white px-4 py-5 sm:sp-6">
            <div className="col-span-6">
                      <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                        Full Name
                      </label>
                      <input
                        type="name"
                        name="name"
                        id="name"
                        onChange={event => setFormData({...formData, name: event.target.value})} value={formData.name}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm w-[90%] border-2 text-[14px] p-[6px] mb-[3px] rounded block mb-10px"
                      />
                    </div>
                    <div className="bg-white ">
                  <div className="grid grid-cols-6 gap-6">
             
                  <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                      <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
                        Company
                      </label>
                      <input
                        type="text"
                        name="postal-code"
                        id="postal-code"
                        autoComplete="postal-code"
                        onChange={event => setFormData({...formData, company: event.target.value})} value={formData.company}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm w-[90%] border-2 text-[14px] p-[6px] mb-[3px] rounded block mb-10px"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                      <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                        VAT ID
                      </label>
                      <input
                        type="text"
                        name="region"
                        id="region"
                        autoComplete="address-level1"
                        onChange={event => setFormData({...formData, vatId: event.target.value})} value={formData.vatId}

                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm w-[90%] border-2 text-[14px] p-[6px] mb-[3px] rounded block mb-10px"
                      />
                    </div>

                  
                    </div>
                    </div>
                  

            <div className="bg-white ">
                  <div className="grid grid-cols-6 gap-6">
             
                  <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                      <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
                        Phone
                      </label>
                      <input
                        type="number"
                        name="postal-code"
                        id="postal-code"
                        autoComplete="postal-code"
                        onChange={event => setFormData({...formData, phone: event.target.value})} value={formData.phone}

                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm w-[90%] border-2 text-[14px] p-[6px] mb-[3px] rounded block mb-10px"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                      <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                        Email
                      </label>
                      <input
                        type="email"
                        name="region"
                        id="region"
                        autoComplete="address-level1"
                        onChange={event => setFormData({...formData, email: event.target.value})} value={formData.email}

                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm w-[90%] border-2 text-[14px] p-[6px] mb-[3px] rounded block mb-10px"
                      />
                    </div>

                  
                    </div>
                    </div>

                    <div className="col-span-6">
                      <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                        Street Address
                      </label>
                      <input
                        type="text"
                        name="street-address"
                        id="street-address"
                        autoComplete="street-address"
                        onChange={event => setFormData({...formData, street: event.target.value})} value={formData.street}

                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm w-[90%] border-2 text-[14px] p-[6px] mb-[3px] rounded block mb-10px"
                      />
                    </div>
           
               

               



            

                    <div className="bg-white ">
                  <div className="grid grid-cols-6 gap-6">
             

               

        

                  

                    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                      <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                        Location
                      </label>
                      <select className="w-[100%] inline px-[20px] bg-white mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm w-[90%] border-2 text-[14px] p-[6px] mb-[3px] rounded block mb-10px" name="section" id="section" defaultValue={formData.location} onChange={event => setFormData({...formData, location: event.target.value})} value={formData.location} >
                <option className="" value="Berlin and surroundings">{t('berlin')}</option>
                <option value="Hamburg and surroundings">{t('hamburg')}</option>
                <option value="Munich and surroundings">{t('munich')}</option>
                <option value="Cologne  and surroundings">{t('cologne')}</option>
                <option value="Frankfurt and surroundings">{t('frankfurt')}</option>
                <option value="Stuttgart and surroundings">{t('stuttgart')}</option>
                <option value="Düsseldorf and surroundings">{t('düsseldorf')}</option>
                <option value="Leipzip and surroundings">{t('leipzip')}</option>
                <option value="Dortmund and surroundings">{t('dortmund')}</option>
                <option value="Essen and surroundings">{t('essen')}</option>
                <option value="Bresmen and surroundings">{t('bresmen')}</option>
                <option value="Essen and surroundings">{t('dresden')}</option>
                <option value="Hannover and surroundings">{t('hannover')}</option>
                <option value="Nuremberg and surroundings">{t('nuremberg')}</option>
                <option value="Duisburg and surroundings">{t('duisburg')}</option>
                <option value="Bochum and surroundings">{t('bochum')}</option>
                <option value="Wuppertal and surroundings">{t('wuppertal')}</option>
                <option value="Bielefeld and surroundings">{t('bielefeld')}</option>
                <option value="Bonn and surroundings">{t('bonn')}</option>
                <option value="Münster and surroundings">{t('münster')}</option>
            </select> 
                     
                    </div>

                 

                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
                        ZIP / Postal code
                      </label>
                      <input
                        type="number"
                        name="postal-code"
                        id="postal-code"
                        autoComplete="postal-code"
                        onChange={event => setFormData({...formData, postcode: event.target.value})} value={formData.postcode}

                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm w-[90%] border-2 text-[14px] p-[6px] mb-[3px] rounded block mb-10px"
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                        Radius Of Work (km)
                      </label>
                      <input
                        type="number"
                        name="region"
                        id="region"
                        autoComplete="address-level1"
                        onChange={event => setFormData({...formData, radius: event.target.value})} value={formData.radius}

                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm w-[90%] border-2 text-[14px] p-[6px] mb-[3px] rounded block mb-10px"
                      />
                    </div>
                    </div>
                    </div>
          
                    <div className="bg-gray-50 py-3 text-right sm:px-1">
                  <button
                    type="submit"
                    onClick ={handleInfo}
                    className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-bold text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 w-[100%] bg-indigo-400 via-[#620000] to-[#550000]"
                  >
                    Update
                  </button>
                </div>
               


                


      </div>
      </div>
      </div>
      </div> }
      </div>
    </div>
</form>

    

  
 


        
    </section>
  )
}

export default EditSub

