import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

//Defaults
import HeadBar from "../partials/HeadBar";
import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import config from "../config/config";

import { FaChevronCircleDown, FaFileInvoice } from "react-icons/fa";

import Select from "react-select";

import { FiDownload } from "react-icons/fi";
import { BsCalendar } from "react-icons/bs";
import { BsSearch } from "react-icons/bs";
import { TbFileInvoice } from "react-icons/tb";
import Block from "../partials/Block";

const options = [
  { value: "1", label: "Älteste" },
  { value: "-1", label: "Neueste" },
];

function Revenue() {
  const componentRef = useRef(null);
  const navigate = useNavigate();

  //Table components
  const [orderData, setOrderData] = useState([]);
  const [subData, setSubData] = useState([]);
  const [push, setPush] = useState(false);
  const [load, setLoad] = useState(false);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState(-1);

  const [totalUserRev, setTotalUserRev] = useState(0);
  const [totalSubRev, setTotalSubRev] = useState(0);
  const [totCost, setTotCost] = useState(0);

  const [skip, setSkip] = useState(0);
  const [success, setSuccess] = useState("");
  const [reason, setReason] = useState("");
  const [showModal, setShowModal] = useState(false);

  //Default
  const [isOpen, setIsOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedOption, setSelectedOption] = useState("");

  //Default functions
  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleOptionChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setSort(selectedOption.value);
  };

  const redirect_invoice_page = (id) => {
    localStorage.setItem("user_invoice_id", id);
    navigate("/orderinvoices");
  };

  const convertDate = (date) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    const formattedDate = new Date(date).toLocaleDateString("de-DE", options);
    // console.log(formattedDate);
    return formattedDate;
  };

  const handleToggle = (id) => {
    const current_row = document.getElementById(id);
    current_row.classList.toggle("expand");
    setIsOpen(!isOpen);
  };

  function exportCSV() {
    let endDateTemp, startDateTemp;
    if (
      startDate === null ||
      endDate === null ||
      startDate == null ||
      endDate == null
    ) {
      startDateTemp = 0;
      endDateTemp = 0;
    } else {
      startDateTemp = startDate;
      endDateTemp = endDate;
    }

    fetch(
      `${config.baseUrl}/api/acceptedoffers/export?search=${search}&sort=${sort}&skip=${skip}&datemin=${startDateTemp}&datemax=${endDateTemp}`
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        const filename =
          "Einnahmen " +
          "_" +
          new Date().getDate() +
          "-" +
          (new Date().getMonth() + 1) +
          "-" +
          new Date().getFullYear() +
          "_" +
          new Date().getHours() +
          "h-" +
          new Date().getMinutes() +
          "m-" +
          new Date().getSeconds() +
          "s.csv";
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      });
  }

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    const interval = setTimeout(() => {
      setSuccess("");
      navigate("/sentoffers");
    }, 5000);

    return () => clearInterval(interval);
  };

  const view = (id) => {
    localStorage.setItem("viewRevenueId", id);
    navigate("/view-revenue");
  };


  useEffect(() => {
    fetch(
      `${config.baseUrl}/api/sub/revenue?id=${localStorage.getItem("em_sub_id")}&search=${search}&sort=${sort}&skip=${skip}`
    )
      .then((response) => response.json())
      .then((data) => {

      setSubData(data);
        const totalCost = data.reduce(
          (total, request) =>
            total +
            (request.cost - (request.cost*request.userper / 100)) ,
          0
        );
        const formattedCost = totalCost.toFixed(0);
        console.log(formattedCost)
        setTotalSubRev(formattedCost);
      });
  }, [load, push, search, sort, skip]);


  const sortOrder = (event) => {
    setSort(event.target.value);
  };

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <HeadBar 
            title={"Einnahmen"} 
            description={"Umsatz auf einen Blick"}
            exportCSV={exportCSV}
            isVisible={true}
             />
             
            
          </div>
        <main ref={componentRef}>
         

          <div className=" max-w-min-content mb-[100px]  col-span-full xl:col-span-12   ml-[45px]">
          
            <div className="relative px-4 ">
              <div className="flex  justify-between items-center border rounded-md py-2 px-5 bg-slate-600 max-w-[1200px]">
                <div>
                  <BsCalendar className="text-white mr-4 inline-block" />
                  <input
                    type="date"
                    className="text-[14px] font-semibold text-slate-700 focus:outline-none border-0 p-2 w-40 rounded-md  border-gray-300"
                    value={startDate}
                    onChange={handleStartDateChange}
                  />
                  <p className="inline-block text-white px-4 ">to</p>
                  <input
                    type="date"
                    className="text-[14px] font-semibold text-slate-700 focus:outline-none border-0 p-2 w-40 rounded-md  border-gray-300"
                    value={endDate}
                    onChange={handleEndDateChange}
                  />
                </div>
          
                <div className='flex items-center'>
        <BsSearch className='text-white mr-3'/>
        <input type="text" className='rounded-sm shadow-lg text-slate-700 text-sm w-[200px] px-[15px] mr-8 py-2 inline-block rounded mr-4 focus:none' placeholder="Suche nach Feldern" value={search} onChange={(event) =>setSearch(event.target.value)}/>

       <Select

    className='sm:w-[200px]  text-[12px] shadow-lg  shadow-lg text-slate-600 sm:text-sm text-[12px] rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-[200px] '
      
      options={options}
      value={selectedOption}
      onChange={handleOptionChange}
      placeholder="Anfragen filtern"
    />
    </div>
              </div>
            </div>
            <div className="p-3">
              {/* Table */}
              <div className="overflow-x-auto">
                {/* </select> */}

                {/* </div> */}
                <table className="table-auto bg-white border shadow-sm w-[100%] max-w-[1200px] ">
                  {/* Table header */}
                  <>
                    <thead className="text-xs font-semibold uppercase text-slate-600 bg-slate-200">
                      <tr>
                        <th className="p-2 whitespace-nowrap">
                          <div className="font-bold text-left">
                            Customer Name
                          </div>
                        </th>
                        <th className="p-2 whitespace-nowrap">
                          <div className="font-bold text-left">Erhalten </div>
                        </th>
                        <th className="p-2 whitespace-nowrap">
                          <div className="font-bold text-left">Bezahlt</div>
                        </th>
                    
                        <th className="p-2 whitespace-nowrap">
                          <div className="font-bold text-left">Profitieren</div>
                        </th>

                        <th className="p-2 whitespace-nowrap">
                          <div className="font-bold text-left"> Datum </div>
                        </th>
                      
                        <th className="p-2 whitespace-nowrap">
                          <div className="font-bold text-left">Rechnung</div>
                        </th>

                        <th className="p-2 whitespace-nowrap">
                          <div className="font-bold text-left">Sicht</div>
                        </th>

                        <th className="p-2 whitespace-nowrap">
                          <div className="font-bold text-left"></div>
                        </th>
                      </tr>
                    </thead>
                  </>

                  <tbody className="text-sm divide-y divide-slate-100">
                    {subData !== "" &&
                      subData.map((order) => {
                        return (
                          <>
                            <React.Fragment>
                              <tr className="tr-main w-[100]" key={order._id}>
                                <td className="p-2 whitespace-nowrap">
                                <div className="font-normal text-slate-800">
                                    {order.firstName + " " + order.lastName}
                                  </div>
                                </td>
                                <td className="p-2 whitespace-nowrap">
                                <div className="font-semibold text-green-500">
                                  {(order.cost * 70) / 100} €
                                    
                                  </div>
                                </td>
                                
                                <td className="p-2 whitespace-nowrap">
                                <div className="font-normal text-slate-800">
                                  {(order.cost * 70) / 1000} €
                                    
                                  </div>
                                </td>

                                <td className="p-2 whitespace-nowrap">
                                <div className="font-semibold text-green-500">
                                  {((order.cost * 70) / 100) - ((order.cost * 70) / 1000)} €
                                    
                                  </div>
                                </td>

                                
                                <td className="p-2 whitespace-nowrap">
                                <div className="font-normal text-slate-800">
                                    {order.date}
                                  </div>
                                </td>

                                

                                <td className="p-2 whitespace-nowrap">
                                  <div className="text-left">
                                  <button
                                      className="bg-green-500 inline-flex items-center gap-2 rounded-md px-4 py-2 text-sm text-white  hover:bg-green-800 transition-all hover:text-gray-300 focus:relative"
                                      onClick={() =>
                                        redirect_invoice_page(order._id)
                                      }                                    >
                                    <TbFileInvoice  className="text-white" />
                                      Rechnung
                                    </button>
                                  </div>
                                </td>

                                <td className="p-2 whitespace-nowrap">
                                  <div className="text-left">
                                  <button
                                      className="bg-gray-200 inline-flex items-center gap-2 rounded-md px-4 py-2 text-sm text-gray-500  hover:bg-gray-300 transition-all hover:text-gray-800 focus:relative"
                                      onClick={() => view(order._id)}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        class="h-4 w-4"
                                      >
                                        <path
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                        />
                                        <path
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                        />
                                      </svg>
                                      Sicht
                                    </button>
                                  </div>
                                </td>

                                <td className="p-2 whitespace-nowrap">
                                  <button
                                    onClick={() => {
                                      handleToggle(order._id);
                                    }}
                                    className="text-left"
                                  >
                                    <FaChevronCircleDown
                                      className={`text-[25px] ${
                                        isOpen ? "rotate" : "rotate-alt"
                                      }`}
                                    />{" "}
                                    {/* <img className='w-[20px]' src="./images/expandtoggle.jpg" alt="" /> */}
                                  </button>
                                </td>
                              </tr>
                              <tr className="w-[100] expand" id={order._id}>
                                <td className="p-2 whitespace-nowrap">
                                  <small className="text-[13px] text-left font-semibold">
                                    Id
                                  </small>

                                  <div className="text-left">
                                    EM-{order._id}
                                  </div>
                                </td>

                                <td className="p-2 whitespace-nowrap">
                                  <small className="text-left text-[13px] font-semibold">
                                    Email
                                  </small>

                                  <div className="text-left">
                                    {order.email}{" "}
                                  </div>
                                </td>

                                <td className="p-2 whitespace-nowrap">
                                  <small className="text-left text-[13px] font-semibold">
                                    Phone{" "}
                                  </small>

                                  <div className="text-left">{order.phone}</div>
                                </td>

                                <td className="p-2 whitespace-nowrap">
                                  <small className="text-[13px] text-left font-semibold">
                                    Location
                                  </small>

                                  <div className="text-left">
                                    {order.location}
                                  </div>
                                </td>

                                <td className="p-2 whitespace-nowrap">
                                  <small className="text-[13px] text-left font-semibold">
                                    Postcode
                                  </small>

                                  <div className="text-left">
                                    {order.postcode}
                                  </div>
                                </td>

                                <td className="p-2 whitespace-nowrap">
                                  <small className="text-left font-semibold text-[13px]">
                                    Created on
                                  </small>

                                  <div className="text-left">
                                    {convertDate(order.createdon)}{" "}
                                  </div>
                                </td>
                              </tr>
                            </React.Fragment>
                          </>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Revenue;
