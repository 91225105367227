import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from '../partials/Header'
import Sidebar from '../partials/Sidebar'

const SharedComponent = () => {
  return (
  <>
  
{/* <Sidebar/>
<Header/> */}
<Outlet/>

  </>
  )
}

export default SharedComponent