const config = {

    backendUrl:'https://subcontractor.entsorg-meister.de',
    baseUrl:   'https://subcontractor.entsorg-meister.de',
    frontendUrl:  'https://subcontractor.entsorg-meister.de'

    // backendUrl:'http://localhost:5000',
    // baseUrl:'http://localhost:5000',
    // frontendUrl:'http://localhost:3000',

  }
  
  export default config