import Next from './components/Next.js';
import Home from'./components/Home.js';


import ViewNewRequest from './pages/ViewNewRequest.jsx';
import ViewCompletedOrders from './pages/ViewCompletedOrders.jsx';

import AddOrder from'./pages/AddOrder.jsx';

import SentOffers from './pages/SentOffers.jsx';
import EditSentOffer from './pages/EditSentOffer.jsx';
import ReviseCost from './pages/ReviseCost.jsx';
import ManualUserPayments from './pages/ManualUserPayment.jsx';
import ManualSubPayment from './pages/ManualSubPayment.jsx';

import SharedComponent from'./components/SharedComponent.js';
import Error404 from './pages/Error404.jsx';
import Login from './components/Login.js';
import Register from './components/Register.js';
import ScrollToTop from './components/ScrollToTop.js';

import SendQuote from './pages/SendQuote.jsx';
import AcceptedOffers from './pages/AcceptedOffers.jsx';

import CancelledOffers from './pages/CancelledOffers.jsx';
import ActiveOrders from './pages/ActiveOrders.jsx';
import ViewActive from './pages/ViewActiveRequests.jsx';
import CompletedOrders from './pages/CompletedOrders.jsx';
import Revenue from './pages/Revenue.jsx';
import Subcontractors from './pages/Subcontractors.jsx';
import EditNewRequest from './pages/EditNewRequest';
import ShowQuery from './pages/ShowQuery.jsx';
import ViewSubcontractor from './components/ViewSubcontractor'
import EditSub from './components/EditSub'
import OrderHistory from './components/OrderHistory'
import Preview from './components/Preview'

import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import ViewCert from './components/ViewCert.js';
import SentToSubcontractor from './pages/SentToSubcontractor.jsx';
import Alt from './components/Alt.jsx';
import Dashboard from './pages/Dashboard.jsx';
import Dummy from './components/Dummy.jsx';
import Query from './pages/Query.jsx';
import NewRequests from './pages/NewRequests.jsx';

import AddBlog from './pages/AddBlog.jsx';
import OrderInvoices from './pages/OrderInvoices.jsx';
import Profile from './pages/Profile.jsx';

import Payment from './pages/Payment';
import Error403 from './pages/Error403.jsx';
import Success from './pages/Success.jsx';
import ViewRevenue from './pages/ViewRevenue.jsx';
import AcceptTnC from './pages/AcceptTnC.jsx';
import PDF from './pages/PDF.jsx';
// import AddSub from './components/AddSub.js';



function App() {
  return (
    <>
    <Router>

      <ScrollToTop/>
    
<Routes>
<Route path="/login" element={<Login/>} />
<Route path="/alt" element={<Alt/>} />
<Route path="/register" element={<Register/>} />
  <Route path="/" element={<SharedComponent/>} >
    <Route index element={<Dashboard/>} />

    <Route path="/pdf" element={<PDF/>} />
    <Route path="/newrequest" element={<NewRequests/>} />
    <Route path="/viewnewrequest" element={<ViewNewRequest/>} />
    <Route path="/home" element={<Dashboard/>} />
    <Route path="/dummy" element={<Dummy/>} />
    <Route path="/accept-tnc" element={<AcceptTnC/>} />

    <Route path="/orderinvoices" element={<OrderInvoices/>} />
    <Route path="/addblog" element={<AddBlog/>} />
    <Route path="/addorder" element={<AddOrder/>} />
    <Route path="/preview" element={<Preview/>} />
    <Route path="/activeorders" element={<ActiveOrders/>} />
    <Route path="/view-active" element={<ViewActive/>} />
    <Route path="/completedorders" element={<CompletedOrders/>} />
    <Route path="/payment" element={<Payment/>} />

    <Route path="/sentoffers" element={<SentOffers/>} />
    <Route path="/editsentoffer" element={<EditSentOffer/>} />
    <Route path="/revisecost" element={<ReviseCost/>} />
    <Route path="/manualuserpayments" element={<ManualUserPayments/>} />
    <Route path="/manualsubpayments" element={<ManualSubPayment/>} />

    <Route path="/cancelledoffers" element={<CancelledOffers/>} />
    <Route path="/acceptedoffers" element={<AcceptedOffers/>} />
    <Route path="/orderhistory" element={<OrderHistory/>} />
    <Route path="/revenue" element={<Revenue/>} />
    <Route path="/next" element={<Next/>} />
    <Route path="/sendquote" element={<SendQuote/>} />
    <Route path="/view-revenue" element={<ViewRevenue/>} />
    
    <Route path="/senttosub" element={<SentToSubcontractor/>} />
    <Route path="/subcontractors" element={<Subcontractors/>} />
    <Route path="/view-completed" element={<ViewCompletedOrders/>} />
    <Route path="/view-subcontractor" element={<ViewSubcontractor/>} />
    <Route path="/view-cert" element={<ViewCert/>} />
    <Route path="/edit-sub" element={<EditSub/>} />
    {/* <Route path="/addsub" element={<AddSub/>} /> */}

    <Route path="/query" element={<Query/>} />
    <Route path="/showquery" element={<ShowQuery/>} />



    <Route path="/profile" element={<Profile/>} />
    <Route path="/success" element={<Success/>} />
    <Route path="/orderhistory" element={<OrderHistory/>} />
    <Route path="/edit" element={<EditNewRequest/>} />
    <Route path="/error403" element={<Error404/>} />
    <Route path="*" element={<Error404/>} />
   
  </Route>
   
</Routes>


    </Router>


      
    
    </>
    
  );
}

export default App;
