import React, { useState, useEffect , useRef } from 'react';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';import {  useNavigate } from "react-router-dom"
import { useTranslation } from 'react-i18next'

import { FaChevronCircleDown } from 'react-icons/fa';
import { FiDownload } from 'react-icons/fi';

import moment from 'moment';
import Select from 'react-select'
import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import WelcomeBanner from '../partials/WelcomeBanner';
import config from '../config/config';
import Block from '../partials/Block';

import { BsCalendar } from 'react-icons/bs';
import { BsSearch } from 'react-icons/bs';
import HeadBar from '../partials/HeadBar';

const options = [
  { value: '1', label: 'Älteste' },
  { value: '-1', label: 'Neueste' },

]


function Query() {

  const componentRef = useRef(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [users, setUsers] = useState('')
  const [registeredUsers, setRegisteredUsers] = useState('')
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedOption, setSelectedOption] = useState("");

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

 


  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleOptionChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setSort(selectedOption.value)
  };

    
  const [totalActive, setTotalActive] =useState(0)
  const [totalComplete, setTotalComplete] =useState(0)

  const [orderData, setOrderData] = useState('')
  const [push, setPush] = useState(false)
  const [load, setLoad] = useState(false)
  const [sub, setSub] = useState('')
  const [search, setSearch] = useState('')
  const [sort, setSort] = useState(-1)
  const [skip, setSkip] =useState(0)
  const [isOpen, setIsOpen] = useState(false);

  const convertDate = date => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const formattedDate = new Date(date).toLocaleDateString('de-DE', options);
    console.log(formattedDate);
    return formattedDate;
  };


  useEffect(() => {
    fetch("https://admin.entsorg-meister.de/api/totalactive")
    .then(response => response.json())
    .then((data) => setTotalActive(data.count))
  },[])

  useEffect(() => {
    fetch("https://admin.entsorg-meister.de/api/totalcomplete")
    .then(response => response.json())
    .then((data) => setTotalComplete(data.count))
  },[])

useEffect(() => {
  fetch(`${config.baseUrl}/totalRegisteredUsers`)
  .then(response => response.json())
  .then((data) =>{
    setRegisteredUsers(data.count)
  })
},[])

useEffect(() => {
  fetch(`${config.baseUrl}/totalUsers`)
  .then(response => response.json())
  .then((data) =>{
    setUsers(data.count)
  })
},[])

const navigate = useNavigate();





useEffect(() => {
    fetch(`${config.baseUrl}/api/query?search=${search}&sort=${sort}&skip=${skip}`)
    .then(response => response.json())
    .then((data) => setOrderData(data))
  },[ load, push, search, sort, skip])



  function downloadPDF() {
    html2canvas(componentRef.current).then(canvas => {
      const pdf = new jsPDF('l', 'mm', 'a3');
      pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0);
      const dateTemp = "Benutzerabfragen"+"_"+new Date().getDate()+"-" +(new Date().getMonth()+1)+"-" +new Date().getFullYear()+"_" +new Date().getHours()+"h-" +new Date().getMinutes()+"m-" +new Date().getSeconds()+"s-" +new Date().getMilliseconds()+"ms.pdf"
      pdf.save(dateTemp)
    });
  }


const view = (id) => {
    localStorage.setItem('query_id', id)
    navigate('/showquery')
  }


const sortOrder = (event) => {
  setSort(event.target.value.value)
}


 
async function onDelete(id) { 
 

    const response = await fetch(`${config.baseUrl}/api/deletequery`, {

      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
       id
      }),
    })

    const data = await response.json()
    console.log(data)

    if (data.status === 'ok') {
      console.log("data")
      setOrderData(orderData.filter((val)=>{
        return orderData._id!== id
      }))
      setPush(!push)
      setLoad(!load)

 
    }
  }

  return (
    <div className="flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main  ref={componentRef}>   
               <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            {/* Welcome banner */}
            <HeadBar title={"Benutzerabfragen"} description={"Benutzeranfragen aus dem Kontaktformular der Benutzerwebsite"}/>

            {/* Dashboard actions */}


          
                
           

          </div>
          <div className=" max-w-min-content  col-span-full xl:col-span-12 bg-white shadow-lg rounded-sm border border-slate-200 ml-[45px]">
      <header className="px-5 py-6 border-b border-slate-100">
      <h2 className='font-bold text-slate-800 text-[26px]  '> Benutzerabfragen<button type="button" onClick={downloadPDF} class="font-semibold inline-block ml-4 text-white bg-violet-500 hover:bg-violet-800  rounded-sm shadow-lg text-sm px-6 py-1.5  ">
      <FiDownload  className='text-white text-[18px] mr-2 mb-0.75 inline-block'  />       Export
                          </button></h2>
      </header>
      <div className="relative px-4">
      <div className="flex  justify-between items-center border rounded-md py-2 px-5 bg-slate-600 max-w-[1100px]">
        <div>
<BsCalendar className='text-white mr-4 inline-block'/>
        <input
          type="date"
          className="text-[14px] font-semibold text-slate-700 focus:outline-none border-0 p-2 w-40 rounded-md  border-gray-300"
          value={startDate}
          onChange={handleStartDateChange}
        />
        <p className="inline-block text-white px-4 ">to</p>
        <input
          type="date"
          className="text-[14px] font-semibold text-slate-700 focus:outline-none border-0 p-2 w-40 rounded-md  border-gray-300"
          value={endDate}
          onChange={handleEndDateChange}
        />
        </div>
        <div></div>
        <div className='flex items-center'>
        <BsSearch className='text-white mr-3'/>
        <input type="text" className='rounded-sm shadow-lg text-slate-700 text-sm w-[200px] px-[15px] mr-8 py-2 inline-block rounded mr-4 focus:none' placeholder="Suche nach Feldern" value={search} onChange={(event) =>setSearch(event.target.value)}/>

       <Select

    className='sm:w-[150px] font-semibold text-[12px] bg-blue-500 shadow-lg  shadow-lg text-slate-700 sm:text-sm text-[12px] rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-[150px] '
      
      options={options}
      value={selectedOption}
      onChange={handleOptionChange}
      placeholder="Anfragen filtern"
    />
    </div>
      </div>
     
    </div>
      <div className="p-3">
    
        <div className="overflow-x-auto">

          <table className="table-auto ">
            

            <>
            <thead className="text-xs font-semibold uppercase text-slate-600 bg-slate-200">
              <tr>
              <th className="p-2 whitespace-nowrap">
                  <div className="font-bold text-left ">Query Status</div>
                </th>
             
                <th className="p-2 whitespace-nowrap">
                  <div className="font-bold text-left">Name</div>
                </th>

               
                <th className="p-2 whitespace-nowrap">
                  <div className="font-bold text-left">Phone</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-bold text-left">Email</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-bold text-left">Postcode</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-bold text-left">Query</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-bold text-left">Date</div>
                </th> 
                                             
                <th className="p-2 whitespace-nowrap">
                  <div className="font-bold text-left">View</div>
                </th>                                
                <th className="p-2 whitespace-nowrap">
                  <div className="font-bold text-left">Mark as Resolved</div>
                </th>                                
                                      
              </tr>
            </thead>
            </>

            <tbody className="text-sm divide-y divide-slate-100">
              {orderData!== '' &&
                orderData.map(order => {
                  return (
                    <>
                 
                    <React.Fragment>
                    <tr className='tr-main w-[100]' key={order._id}>

                     <td className="p-2 whitespace-nowrap">
                     <div className="text-left">
                     <button type="button" class=" bg-red-100 text-red-500 cursor-default  font-semibold rounded-sm text-sm px-6 py-1.5  ">
                       Unresolved
                       </button>
                     </div>
                   </td> 
                
                 
                         
                      <td className="p-2 whitespace-nowrap">
                          <div className="font-semibold text-slate-800">{order.name}</div>
                      </td>
                    
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">{order.phone}</div>
                      </td> 
                    
                      <td className ="p-2 whitespace-nowrap">
                        <div className="text-left">{order.email}</div>
                      </td> 
                      <td className ="p-2 whitespace-nowrap">
                        <div className="text-left">{order.pin}</div>
                      </td> 
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">{order.query.substring(0, 30) + "..."}</div>
                      </td> 
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">{(order.registeredOn).slice(0,10)}</div>
                      </td> 
                     
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">
                        <button onClick={() =>view(order._id)} type="button" class="text-white bg-yellow-500 hover:bg-yellow-600  font-medium rounded-lg text-sm px-6 py-1.5 ">
                          View
                          </button>
                        </div>
                      </td> 

                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">
                        <button type="button" onClick ={(() => onDelete(order._id))} class="text-white bg-red-500 hover:bg-red-800  font-medium rounded-lg text-sm px-6 py-1.5 ">
                          Mark as Resolved
                          </button>
                        </div>
                      </td> 
                
                  
                      
                    </tr>
              
                    </React.Fragment>
                    </>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
        </main>

       

      </div>
    </div>
  );
}

export default Query;