import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next'


const ViewSubcontractor = () => {
    const { t } = useTranslation()
const [name, setName] = useState('')
const [loc, setLoc] = useState('')
const [postcode, setPostcode] = useState('')
const [phone, setPhone] = useState('')
const [email, setEmail] = useState('')
const [address, setAddress] = useState('')
const [vatId, setVatId] = useState('')
const [company, setCompany] = useState('')
const [loading, setLoading] = useState(true)
const [loadingFile, setLoadingFile] = useState(true)
const [radius, setRadius] = useState(null)


 
 
  useEffect(() => {
    
    fetch(`https://admin.entsorg-meister.de/api/viewsubdata?id=${localStorage.getItem('viewSub_id')}`)
    .then(response => response.json())
    .then((data) =>{
      setEmail(data.email)
      setLoading(false)
        setName(data.name)
        setCompany(data.company)
        setPostcode(data.postcode)
        setEmail(data.email)
        setPhone(data.phone)
        setLoc(data.location)
        setAddress(data.street)
        setRadius(data.radius)
        setVatId(data.vat)
      
   
    })
    .catch((error)=> console.log(error))
  },[])

 


  return (
    <section className="main overflow-y-auto ml-[300px] z-0 top-0 w-[calc(100vw-300px)] pt-[30px] pb-[100px] right-0 mt-[70px] min-h-[calc(100vh-70px)] bg-white">

    <h2 className='font-bold text-[#0e0e2b] text-[30px] mb-[30px] ml-[120px]'>{t('subcontractor-info')}</h2>
   {loading && <p className='ml-[300px] p-[30px] font-bold text-[30px] text-blue-400'>{t('loading-data')} <img className='inline-block' src="./images/spin.gif" alt="" /></p>
}

{ !loading && <div className='bg-white w-[90%] h-[100%] p-[25px] shadow-lg m-[30px] border-2 border-grey ml-[120px]'>
   <div className='px-[20px] py-[10px]'>
    <label className='text-[#33333] font-bold ' htmlFor="">{t('name')} : </label>
    <span className=''>{name}</span>
   </div>
   <hr />
   <div className='px-[20px] py-[10px]'>
    <label className='text-[#33333] font-bold ' htmlFor="">{t('company')} : </label>
    <span className=''>{company}</span>
   </div>
   <hr />
   <div className='px-[20px] py-[10px]'>
   <label className='text-[#33333] font-bold ' >{t('phone')} : </label>
    <span>{phone}</span>
   </div>
   <hr />
   <div className='px-[20px] py-[10px]'>
   <label className='text-[#33333] font-bold ' >{t('email')} : </label>
    <span>{email}</span>
   </div>
   <hr />
   <div className='px-[20px] py-[10px]'>
   <label className='text-[#33333] font-bold ' >{t('postcode')} : </label>
    <span>{postcode}</span>
   </div>
   <hr />
   <div className='px-[20px] py-[10px]'>
   <label className='text-[#33333] font-bold ' >{t('location')} : </label>
    <span>{loc}</span>
   </div>
   <div className='px-[20px] py-[10px]'>
   <label className='text-[#33333] font-bold ' >{t('radius')} : </label>
    <span>{radius} km</span>
   </div>
   <hr />
   <div className='px-[20px] py-[10px]'>
   <label className='text-[#33333] font-bold ' >{t('vatid')} : </label>
    <span>{vatId}</span>
   </div>
   <div className='px-[20px] py-[10px]'>
   <label className='text-[#33333] font-bold ' >{t('address')} : </label>
    <span>{address}</span>
   </div>
   <hr />
   </div>}

   {/* {(!loading && loadingFile) && <p className='ml-[300px] p-[30px] font-bold text-[30px] text-blue-400'>{t('loading-data')} <img className='inline-block' src="./images/spin.gif" alt="" /></p>}
   { (!loading && !loadingFile) && <div className='bg-white w-[90%] h-[100%] p-[25px] shadow-lg m-[30px] border-2 border-grey ml-[120px]'>
   <div className='px-[20px] py-[10px]'>
    <label className='text-[#33333] font-bold ' htmlFor="">{t('name')} : </label>
    <span className=''>Filw will be shown here</span>
   </div>
   </div>} */}


</section>
  )
}

export default ViewSubcontractor

