import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

//Defaults
import { IoCloudDoneSharp } from 'react-icons/io5';
import { FiDownload } from 'react-icons/fi';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';

import HeadBar from "../partials/HeadBar";
import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import config from "../config/config";

import Select from "react-select";

const LocationOptions = [
  { label: "Berlin", value: "Berlin" },
  { label: "Hamburg", value: "Hamburg" },
  { label: "München", value: "München" },
  { label: "Köln", value: "Köln" },
  { label: "Stuttgart", value: "Stuttgart" },
  { label: "Düsseldorf", value: "Düsseldorf" },
  { label: "Leipzig", value: "Leipzig" },
  { label: "Dortmund", value: "Dortmund" },
  { label: "Essen", value: "Essen" },
  { label: "Bremen", value: "Bremen" },
  { label: "Dresden", value: "Dresden" },
  { label: "Hannover", value: "Hannover" },
  { label: "Nürnberg", value: "Nürnberg" },
  { label: "Duisburg", value: "Duisburg" },
  { label: "Bochum", value: "Bochum" },
  { label: "Wuppertal", value: "Wuppertal" },
  { label: "Bielefeld", value: "Bielefeld" },
  { label: "Bonn", value: "Bonn" },
  { label: "Münster", value: "Münster" },
];

const goToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

function Profile() {
  const { t } = useTranslation();

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedLocationOption, setSelectedLocationOption] = useState("");
  const [uploadMessage, setUploadMessage] = useState("");
  const [upload, setUpload] = useState("");
  const [success, setSuccess] = useState("");
  const [certificate, setCertificate] = useState("");
  const [passShow, setPassShow] = useState(false);

  let [password, setPassword] = useState("");
  let [confirmPassword, setConfirmPassword] = useState("");

  const [confirmPassErr, setConfirmPassErr] = useState("");
  const [passErr, setPassErr] = useState("");

  const [sub, setSub] = useState({});

  const handleLocationChange = (selectedOption) => {
    setSelectedLocationOption(selectedOption);
  };

  const email = localStorage.getItem("email");
  const id = localStorage.getItem("em_sub_id");

  useEffect(() => {
    fetch(
      `${config.baseUrl}/api/sub/profile/read?id=${localStorage.getItem("em_sub_id")}`
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setSub(data.sub);
        const selectedValue = data.sub.location;
        const selectedOption = LocationOptions.find(
          (option) => option.value === selectedValue
        );
        setSelectedLocationOption(selectedOption);
      })
      .catch((error) => console.log(error));
  }, []);


  useEffect(() => {
    fetch(`${config.baseUrl}/api/sub/vat/read?id=${localStorage.getItem("em_sub_id")}`)
      .then((response) => response.json())
      .then((data) => {
        setUpload(data.status);
      })
      .catch((error) => console.log(error));
  }, [uploadMessage]);



    async function handleInfo(event) {
    const response = await fetch(`${config.baseUrl}/api/sub/profile/update`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: sub._id,
        firstName: sub.firstName,
        lastName: sub.lastName,
        company: sub.company,
        street: sub.street,
        postcode: sub.postcode,
        location: selectedLocationOption.value,
        phone: sub.phone,
        radius: sub.radius,
        vat: sub.vat,
      }),
    });

    const data = await response.json();

    if (data.status === "ok") {
      goToTop();
      setSuccess("success");
    } else {
      goToTop();
      setSuccess("error");
    }
  } 
  
 
  async function passChange(event) {
    event.preventDefault();

    const response = await fetch(`${config.baseUrl}/api/sub/profile/update`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: sub._id,
        password: password,
       
      }),
    });

    const data = await response.json();

    if (data.status === "ok") {
      goToTop();
      setSuccess("success");
    } else {
      goToTop();
      setSuccess("error");
    }
}

  function handlePassword(e) { 
console.log(e.target.value)
    setPassword(e.target.value)
    if(e.target.value===''){
      setPassErr("empty")
    }
    else if(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,36}$/.test(e.target.value)){
      setPassErr("ok")

    }
    else{
      setPassErr("error")

    }  
}

  function handleConfirmPassword(e) { 
    console.log(e.target.value)

    setConfirmPassword(e.target.value)

    if(e.target.value==='' ){
      setConfirmPassErr("empty")

    }
    else if((/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,36}$/.test(e.target.value)) && e.target.value===password){
      setConfirmPassErr("ok")
    }
    else{
    setConfirmPassErr("error")
    }  
}



  async function handleSubmit(event) {
    event.preventDefault();

  }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const fileUpload = (file) => {
    if (file.size < 5000000) {
      getBase64(file).then((data) => {
        sendFile(data);
      });
    } else {
      setUploadMessage("error");
    }
  };

  async function sendFile(filebase) {
    const response = await fetch(`${config.baseUrl}/api/sub/vat/save`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.getItem("em_sub_id"),
        file: filebase,
      }),
    });

    const data = await response.json();

    if (data.status === "ok") {
      setUploadMessage("success");
    } else {
      setUploadMessage("failed");
    }
  }



  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
          <header className="px-5 py-6 border-b border-slate-100">
            <HeadBar
              title={"Profil"}
              description={"Bearbeite dein Profil"}
            />
             
            </header>
           
<div className="flex justify-around max-w-[1400px] ">


            <div className="w-[800px]   ml-[20px] shadow-black/20 shadow-lg form px-[40px] py-[40px] bg-white rounded-[10px]">
              <form action="" onSubmit={handleSubmit}>
                {success === "success" && (
                  <div className="mb-[20px]">
                    <p className="bg-blue-100 border-[1px] border-solid border-blue-500 w-[100%] px-[30px] py-[10px] ">
                      Informationen aktualisiert
                    </p>
                  </div>
                )}
                {success === "error" && (
                  <div className="mb-[20px]">
                    <p className="bg-red-100 border-[1px] border-solid border-red-500 w-[100%] px-[30px] py-[10px] ">
                      Etwas ist schief gelaufen. Die Informationen konnten nicht gespeichert werden
                    </p>
                  </div>
                )}
                <div className="form-grp bg-[#f7feff] w-[100%] border-[1px] border-indigo/10 shadow-indigo/20 mb-[10px] flex">
                  <label
                    className="bg-slate-700 text-white font-semibold  px-[10px] py-[6px] w-[300px]"
                    htmlFor="Company Name"
                  >
                    Vorname *
                  </label>
                  <input
                    className="w-[100%] inline px-[20px] bg-white"
                    type="name"
                    name="name"
                    id=""
                    onChange={(event) =>
                      setSub({ ...sub, firstName: event.target.value })
                    }
                    value={sub.firstName}
                  />
                </div>

                <div className="form-grp bg-[#f7feff] w-[100%] border-[1px] border-indigo/10 shadow-indigo/20 mb-[10px] flex">
                  <label
                    className="bg-slate-700 text-white font-semibold  px-[10px] py-[6px] w-[300px]"
                    htmlFor="Company Name"
                  >
                    Nachname *
                  </label>
                  <input
                    className="w-[100%] inline px-[20px] bg-white"
                    type="text"
                    name="company"
                    id=""
                    onChange={(event) =>
                      setSub({ ...sub, lastName: event.target.value })
                    }
                    value={sub.lastName}
                  />
                </div>

                <div className="form-grp bg-[#f7feff] w-[100%] border-[1px] border-indigo/10 shadow-indigo/20 mb-[10px] flex">
                  <label
                    className="bg-slate-700 text-white font-semibold  px-[10px] py-[6px] w-[300px]"
                    htmlFor="Company Name"
                  >
                    Email
                  </label>
                  <input
                    className="w-[100%] inline px-[20px] bg-white"
                    type="email"
                    name="email"
                    disabled
                    onChange={(event) =>
                      setSub({ ...sub, email: event.target.value })
                    }
                    value={sub.email}
                  />
                </div>

                <div className="form-grp bg-[#f7feff] w-[100%] border-[1px] border-indigo/10 shadow-indigo/20 mb-[10px] flex">
                  <label
                    className="bg-slate-700 text-white font-semibold  px-[10px] py-[6px] min-w-[215px] "
                    htmlFor="Passwort"
                  >
               Passwort *<AiFillEye onClick= {()=> setPassShow(!passShow)} className="inline-block ml-2 cursor-pointer hover:text-blue-500 transition-all" size={"1.5em"} />
                  </label>
 {  !passShow?           
 <input
                    className="w-[100%] inline px-[20px] bg-white"
                    type="password"
                    name="password"
                    disabled
                    onChange={(event) =>
                      setSub({ ...sub, password: event.target.value })
                    }
                    value={sub.password}
                  /> 
                      :
                  <input
                  className="w-[100%] inline px-[20px] bg-white"
                  type="text"
                  name="password"
                  disabled
                  onChange={(event) =>
                    setSub({ ...sub, password: event.target.value })
                  }
                  value={sub.password}
                 
               />
               
                 
                  }
                </div>

                <div className="form-grp bg-[#f7feff] w-[100%] border-[1px] border-indigo/10 shadow-indigo/20 mb-[10px] flex">
                  <label
                    className="bg-slate-700 text-white font-semibold  px-[10px] py-[6px] w-[300px]"
                    htmlFor="Company Name"
                  >
                    Name der  *
                  </label>
                  <input
                    className="w-[100%] inline px-[20px] bg-white"
                    type="text"
                    name="company"
                    id=""
                    onChange={(event) =>
                      setSub({ ...sub, company: event.target.value })
                    }
                    value={sub.company}
                  />
                </div>

                <div className="form-grp bg-[#f7feff] w-[100%] border-[1px] border-indigo/10 shadow-indigo/20 mb-[10px] flex">
                  <label
                    className="bg-slate-700 text-white font-semibold  px-[10px] py-[6px] w-[300px]"
                    htmlFor="Company Name"
                  >
                    {t("location")} *
                  </label>
                  <Select
                    className="w-[100%] bg-white    text-gray-800 sm:text-sm text-[12px] font-medium rounded-lg focus:ring-blue-500 focus:border-blue-500 block  "
                    options={LocationOptions}
                    value={selectedLocationOption}
                    onChange={handleLocationChange}
                    placeholder="Ort auswählen"
                  />
                </div>
                <div className="form-grp bg-[#f7feff] w-[100%] border-[1px] border-indigo/10 shadow-indigo/20 mb-[10px] flex">
                  <label
                    className="bg-slate-700 text-white font-semibold  px-[10px] py-[6px] w-[300px]"
                    htmlFor="Company Name"
                  >
                    Straße *
                  </label>
                  <input
                    className="w-[100%] inline px-[20px] bg-[#f7feff]"
                    type="text"
                    name="company"
                    id=""
                    onChange={(event) =>
                      setSub({ ...sub, street: event.target.value })
                    }
                    value={sub.street}
                  />
                </div>

                <div className="form-grp bg-[#f7feff] w-[100%] border-[1px] border-indigo/10 shadow-indigo/20 mb-[10px] flex">
                  <label
                    className="bg-slate-700 text-white font-semibold  px-[10px] py-[6px] w-[300px]"
                    htmlFor="Company Name"
                  >
                    {t("postcode")} *
                  </label>
                  <input
                    className="w-[100%] inline px-[20px] bg-white"
                    type="text"
                    name="company"
                    id=""
                    onChange={(event) =>
                      setSub({ ...sub, postcode: event.target.value })
                    }
                    value={sub.postcode}
                  />
                </div>

                <div className="form-grp bg-[#f7feff] w-[100%] border-[1px] border-indigo/10 shadow-indigo/20 mb-[10px] flex">
                  <label
                    className="bg-slate-700 text-white font-semibold  px-[10px] py-[6px] w-[300px]"
                    htmlFor="Company Name"
                  >
                    {t("phone")} *
                  </label>
                  <input
                    className="w-[100%] inline px-[20px] bg-white"
                    type="text"
                    name="company"
                    id=""
                    onChange={(event) =>
                      setSub({ ...sub, phone: event.target.value })
                    }
                    value={sub.phone}
                  />
                </div>

                <div className="form-grp bg-[#f7feff] w-[100%] border-[1px] border-indigo/10 shadow-indigo/20 mb-[10px] flex">
                  <label
                    className="bg-slate-700 text-white font-semibold  px-[10px] py-[6px] w-[300px]"
                    htmlFor="Company Name"
                  >
                    {t("vatid")} *
                  </label>
                  <input
                    className="w-[100%] inline px-[20px] bg-white"
                    type="text"
                    name="company"
                    id=""
                    onChange={(event) =>
                      setSub({ ...sub, vat: event.target.value })
                    }
                    value={sub.vat}
                  />
                </div>

                <div className="form-grp bg-[#f7feff] w-[100%] border-[1px] border-indigo/10 shadow-indigo/20 mb-[10px] flex">
                  <label
                    className="bg-slate-700 text-white font-semibold  px-[10px] py-[6px] w-[300px]"
                    htmlFor="Radius of work"
                  >
                    Arbeitsradius (in km) *
                  </label>
                  <input
                    className="w-[100%] inline px-[20px] bg-[#f7feff]"
                    type="number"
                    min={5}
                    name="company"
                    id=""
                    onChange={(event) =>
                      setSub({ ...sub, radius: event.target.value })
                    }
                    value={sub.radius}
                  />
                </div>


 {(sub.company === "" ||
                    sub.street === "" ||
                    sub.postcode === "" ||
                    sub.location === "" ||
                    sub.phone === null ||
                    sub.phone === "" ||
                    sub.vat === "" ||
                    sub.firstName === "") ?
                    <button
                    className="my-3 block w-full text-slate-800 cursor-not-allowed rounded-lg bg-gray-500 px-5 py-3 text-sm font-medium text-white transition-all "
disabled
              type="button"
             
            >
            Alle Felder sind Pflichtfelder
            </button>
                    : 
                    <button
                    className="my-3 block w-full rounded-lg bg-indigo-600 hover:bg-indigo-500 px-5 py-3 text-sm font-medium text-white transition-all "

              type="submit"
              disabled={
                sub.company === "" ||
                sub.street === "" ||
                sub.postcode === "" ||
                sub.location === "" ||
                sub.phone === null ||
                sub.phone === "" ||
                sub.vat === "" ||
                sub.firstName === ""
              }
              onClick={handleInfo}
            >
              Einträge aktualisieren
            </button>

 }               

              </form>
              <hr />

              <div className="form-grp bg-[#f7feff] w-[100%] border-[1px] border-indigo/10 shadow-indigo/20 mb-[10px]">
               
                {uploadMessage === "error" && (
                  <div className="mb-[20px]">
                    <p className="bg-red-100 border-[1px] border-solid border-red-500 w-[100%] px-[30px] py-[10px] ">
                      Datei ist zu groß
                    </p>
                  </div>
                )}
                {uploadMessage === "failed" && (
                  <div className="mb-[20px]">
                    <p className="bg-red-100 border-[1px] border-solid border-red-500 w-[100%] px-[30px] py-[10px] ">
                    Das Hochladen ist fehlgeschlagen. Bitte versuche es erneut
                    </p>
                  </div>
                )}

                {upload === "invalid" && (
                  <>
                  
                    <p className="text-white mb-[4px] bg-slate-700 font-semibold py-3 px-[30px]">
                    Mehrwertsteuerbescheinigung hochladen
                    </p>
                    <div className="form-grp py-2 bg-[#f7feff] w-[100%] border-[1px] border-indigo/10 shadow-indigo/20 mb-[10px] flex">
                      <input
                        className="w-[100%] inline px-[20px] bg-[#f7feff]"
                        type="file"
                        accept=".pdf"
                        name="file"
                        id="file"
                        onChange={(event) => {
                          const file = event.target.files[0];
                          setUploadMessage("");
                          setCertificate(file);
                        }}
                      />
                      
                      <button
                        className="transition-all shadow-md shadow-[#white]-500/20 hover:shadow-[white]/20   text-white py-3 font-semibold px-6 w-[300px] bg-orange-400 hover:bg-orange-500 rounded-lg "
                        onClick={() => {
                          fileUpload(certificate);
                        }}
                      >
                       Hochladen
                      </button>
                    </div>
                  </>
                )}

                {upload === "ok" && (
                  <>
                    <p className="text-white bg-green-100 border-2 border-green-400 text-green-400 font-semibold py-[6px] px-[20px]">
                    <IoCloudDoneSharp className="inline-block text-[20px] mr-2"/>  Mehrwertsteuerdatei wurde bereits hochgeladen
                    </p>
                  </>
                )}
              </div>
            </div>
    <form
    onSubmit={passChange}
      className="border-slate-200 bg-white mt-6 mb-0 space-y-4 rounded-lg p-4 shadow-lg sm:p-6 lg:p-8"
    >
      <p class="text-center text-lg font-semibold">Kennwort ändern</p>

      


      <div>
        <label for="password" class="sr-only">Passwort</label>

        <div class="relative">
          <input
            type="password"
            name="password"
            value={password}
            onChange={(event) =>
             handlePassword(event)
           }
            minLength={8}
required
className={`w-full rounded-lg p-4 pr-12 text-sm  focus:outline-none focus:border-sky-500 focus:ring-2 focus:ring--500 shadow-sm border ${passErr === 'ok' ?  ' border-green-500 focus:border-green-500 focus:ring-1 focus:ring--500' : 'border-gray-200'}`}
            placeholder="Passwort"
          />

          <span
            class="absolute inset-y-0 right-0 grid place-content-center px-4"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
              />
            </svg>
          </span>
        </div>
      </div>

      <div>
        <label for="password" class="sr-only">Bestätige das Passwort</label>

        <div class="relative">
          <input
            type="password"
            name="confirm_password"
           required
            onChange={(event) =>
              handleConfirmPassword(event)
            }
            value={confirmPassword}
            minLength={8}
            className={`w-full rounded-lg p-4 pr-12 text-sm  focus:outline-none focus:border-sky-500 focus:ring-2 focus:ring--500 shadow-sm border ${confirmPassErr === 'ok' ?  ' border-green-500 focus:border-green-500 focus:ring-1 focus:ring--500' : 'border-gray-200'}`}
            placeholder="Bestätige das Passwort"
          />

          <span
            class="absolute inset-y-0 right-0 grid place-content-center px-4"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
              />
            </svg>
          </span>
        </div>
     
      </div>

    {passErr==="ok" && confirmPassErr==="ok"?
      <button
        type="submit"
        class="block w-full rounded-lg bg-indigo-600 hover:bg-indigo-500 px-5 py-3 text-sm font-medium text-white"
      >
     Bestätigen
            </button>
            :
            <button
            disabled
            class="cursor-not-allowed  block w-full rounded-lg bg-gray-400 px-5 py-3 text-sm font-medium text-gray-100"
          >
         Bestätigen 
                </button>
}

      {/* <p class="text-center text-sm text-gray-500">
         Lost access to your email?
      <a class="underline" href="">Contact Support</a>
      </p> */}
    </form>
    </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Profile;
