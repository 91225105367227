import { useTranslation } from 'react-i18next'

const FourthForm = ({formData, setFormData}) => {
  const { t } = useTranslation()
 
  return (
    <div>
             
   
        <div className="form-group form-main">       
            <li className="list-disc font-semibold text-[#333333]">{t('form-four-heading')}</li>
            <small className="block mb-2 text-[#B6B6B6] font-semibold">{t('form-four-mandatory')}</small>
            <select className="px-[10px] py-[4px] mb-[15px] text-sm" name="section" id="section" onChange={event => setFormData({...formData, section: event.target.value})} value={formData.section} >
                <option className="" selected value="Kitchen">{t('kitchen')}</option>
                <option value="Bedroom">{t('bedroom')}</option>
                <option value="Loft">{t('loft')}</option>
                <option value="Basement">{t('basement')}</option>
                <option value="Others">{t('others')}</option>
            </select> 
           {formData.section === 'Others' ?<><li className="list-disc text-[#333333] font-semibold mb-[6px]">{t('form-four-sub')}</li>
            <input className='bg-[#F3F3F3] font-semibold text-[#676767] px-[20px] mb-[20px] text-align text-[14px] py-[6px]' type="text" placeholder={t('mention-section')} onChange={event => setFormData({...formData, others: event.target.value})} value={formData.others} />
            </>:null }
           

        </div>

       

       
    </div>
  )
}

export default FourthForm