import React, { useState, useEffect , useRef } from 'react';
import {  useNavigate } from "react-router-dom"
import { useTranslation } from 'react-i18next'


//Defaults
import HeadBar from '../partials/HeadBar';
import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import config from '../config/config';

//PDF
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import Select from 'react-select'

import { FaChevronCircleDown } from 'react-icons/fa';
import { FiDownload } from 'react-icons/fi';
import { BsCalendar } from 'react-icons/bs';
import { BsSearch } from 'react-icons/bs';


function ManualSubPayments() {

  const navigate = useNavigate();

  //Table components
  const subOptions = [
   
  ]
  
  
  

  //Default
  const [isOpen, setIsOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [user, setUser] = useState()
  const [success, setSuccess] = useState('')
  const [orderId, setOrderId] = useState('')
  const [name, setName] = useState('')
  const [cost, setCost] = useState('')
  const [userper, setUserper] = useState('')
  const [payable, setPayable] = useState('')
  const [loading, setLoading] = useState('')
  const [source, setSource] = useState('')
  const [transId, setTransId] = useState('')
  const [manualUserPayRemarks, setManualUserPayRemarks] = useState('')
  
  const [selectedSubOption, setSelectedSubOption] = useState("");

  const [subper, setSubper] = useState('')
  const [subcontractor, setSubcontractor] = useState('')
 
  
  const { t } = useTranslation()

  const goToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
    
    const interval = setTimeout(() => {
        setSuccess('')
     
       }, 5000);

       return () => clearInterval(interval);

  };

  const handleSubChange = (selectedOption) => {
    setSelectedSubOption(selectedOption);
  };
  
const id=localStorage.getItem('editSub_id')

 
  useEffect(() => {
    fetch(`${config.baseUrl}/api/userSubDetails?id=${localStorage.getItem('manualPaymentId')}`)
    .then(response => response.json())
    .then((data) =>{
      setUser(data)
    setName(data.firstName +" " + data.lastName)
    setCost(data.cost)
    setSubper(data.subper)
    setPayable(data.cost*data.subper/100)
    setLoading(false)

    })
    .catch((error)=> console.log(error))
  },[])

  useEffect(() => {
    setOrderId(localStorage.getItem('manualPaymentId'))

    })
  

  

  async function handleSubmit(event) { 
    event.preventDefault()
}


async function handleInfo(event) { 
    const response = await fetch(`${config.baseUrl}/api/updateSubManualPay`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: orderId,
        transIdsub:transId,
        manualSubPayRemarks:manualUserPayRemarks,
        sourceSub: source,
        subcontractor: subcontractor
      }),
    })

    const data = await response.json()

    if (data.status === 'ok') {
        goToTop()
        setSuccess("success")
      }
      else{
        goToTop()
        setSuccess("error")
      }

  }
 

  


  return (
    <div className="flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main  >
        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
    
    <HeadBar title={"Zahlungen manuell aktualisieren"} description={"Fügen Sie manuelle Zahlungen hinzu, um den Status zu aktualisieren"}/>
        <form  onSubmit={handleSubmit}>
   
   <div className=' ml-[20px] w-[90vw] max-w-[1000px] rounded text-[#333]  flex justify-start  p-[45px] bg-white shadow-lg border-2 border-grey '>
     <div>
     {loading && <p className='ml-[300px] p-[30px] font-bold text-[30px] text-blue-400'>{t('loading-data')} <img className='inline-block' src="./images/spin.gif" alt="" /></p>
}
{!loading &&   
 <div className='w-[90vw]  max-w-[900px]'>
 {success==='success' && <div className='mb-[20px]'>
<p className='bg-blue-100 border-[1px] border-solid border-blue-500 w-[100%] px-[30px] py-[10px] '>Payments updated successfully</p>
</div>}
{success==='error' && <div className='mb-[20px]'>
<p className='bg-red-100 border-[1px] border-solid border-red-500 w-[100%] px-[30px] py-[10px] '>An error occured</p>
</div>}
<div>


  <div className=" sm:overflow-hidden sm:rounded-md">
    <div className="space-y-6 bg-white px-4 py-5 sm:sp-6">
    <div className="col-span-6">
              <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                User Name
              </label>
              <input
                type="name"
                name="name"
                id="name"
                disabled

                onChange={event => setName(event.target.value)} value={name}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm w-[90%] border-2 text-[14px] p-[6px] mb-[3px] rounded block mb-10px"
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
                
                <label className="block text-md font-medium text-gray-800">
                  Select the subcontractor
                </label>
 <Select
    className='sm:w-[400px] bg-gray-50 shadow-lg  border border-gray-300 text-gray-800 sm:text-sm text-[12px] font-medium rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[300px]  '
    options={subOptions}
      value={selectedSubOption}
      onChange={handleSubChange}
      placeholder="Select Subcontractor"
    />

                    </div>
      
            <div className="bg-white ">
          <div className="grid grid-cols-6 gap-6">
     
          <div className="col-span-6 sm:col-span-3 lg:col-span-3">
              <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
                Total Cost (Є)
              </label>
              <input
                type="text"
                disabled

                onChange={event => setCost(event.target.value)} value={cost}

                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm w-[90%] border-2 text-[14px] p-[6px] mb-[3px] rounded block mb-10px"
              />
            </div>

            <div className="col-span-6 sm:col-span-3 lg:col-span-3">
              <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                To be paid by Subcontractor (Є)
              </label>
              <input
                type="text"
                name="region"
                id="region"
                autoComplete="address-level1"
                disabled
                 value={payable}

                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm w-[90%] border-2 text-[14px] p-[6px] mb-[3px] rounded block mb-10px"
              />
            </div>

           
          
            </div>
            </div>
          
          
            <div className="col-span-6">
              <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
    Source</label>
              <input
                type="text"
               placeholder='e.g., Received by Bank Transfer / Cheque / Cash'
                onChange={event => setSource(event.target.value)} value={source}
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm w-[90%] border-2 text-[14px] p-[6px] mb-[3px] rounded block mb-10px"
              />
            </div>

            <div className="col-span-6">
              <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
    Transaction Id</label>
              <input
                type="text"
               required
                onChange={event => setTransId(event.target.value)} value={transId}

                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm w-[90%] border-2 text-[14px] p-[6px] mb-[3px] rounded block mb-10px"
              />
            </div>
   

            <div className="col-span-6">
              <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                Payment Remarks
              </label>
              <input
                type="text"
                required
                onChange={event => setManualUserPayRemarks(event.target.value)} value={manualUserPayRemarks}

                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm w-[90%] border-2 text-[14px] p-[6px] mb-[3px] rounded block mb-10px"
              />
            </div>
   
       

       



    

  
            <div className="bg-gray-50 py-3 text-right sm:px-1">
          <button
            type="submit"
            onClick ={handleInfo}
            className="inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-bold text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 w-[100%] bg-green-400 via-[#620000] to-[#550000]"
          >
            Update
          </button>
        </div>
       


        


</div>
</div>
</div>
</div> }
   </div>
 </div>
</form>
</div>

        </main>

       

      </div>
    </div>
  );
}

export default ManualSubPayments;