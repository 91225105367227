import React from 'react'
import { useTranslation } from 'react-i18next'

const FifthForm = ({formData, setFormData}) => {
  const { t } = useTranslation()

const isChecked = (value) => formData.method === value

const handleRadioClick = (e) => setFormData({...formData, method: e.currentTarget.value})

return (
    <div>
         <div className=" form form-main">       
            <li className="list-disc font-semibold text-[#333333]">{t('form-five-heading')}</li>
            <small className="block mb-5 text-[#B6B6B6] font-semibold">{t('form-five-sub')}</small>
            
           <input className='mb-[6px]' type="radio" name="method" id="elevator"  value="Elevator" checked={isChecked("Elevator")} onChange={handleRadioClick}  />
           <label>{t('elevator')}</label><br />
            <input type="radio" name="method" id="stairs" value="Stairs" checked={isChecked("Stairs")} onChange={handleRadioClick}/>
           <label> {t('stairs')}</label>
           <div>
           <input className='bg-[#F3F3F3] font-semibold text-[#676767] px-[20px] mb-[5px] mt-[20px] text-align text-[14px] py-[6px]' type="number" min={0} max={15} placeholder={t('floor-no')} onChange={event => setFormData({...formData, floor: event.target.value})}  value={formData.floor} />
            <small className="block mb-5 text-[#B6B6B6] font-semibold"></small>
           </div>

 
        </div>

        <div className="form-group form-main">       
            <li className="list-disc text-[#333333] font-semibold">{t('moving')}</li>
            <small className="block mb-5 text-[#B6B6B6] font-semibold">{t('select-dat')}</small>

            <input className='bg-[#F3F3F3] font-semibold text-[#676767] px-[20px] mb-[5px] text-align text-[14px] py-[6px]' type="date" name="date" id="date"  onChange={event => setFormData({...formData, date: event.target.value})} value={formData.date} />
        </div>
       
<div className='mb-[20px]'></div>
        
    </div>
  )
}

export default FifthForm